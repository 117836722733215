<template>
  <v-container fluid>
    <v-card tile>
      <v-card-text>
        <v-row dense>
          <v-btn class="mx-2" color="primary"
            @click="successAction({ action: 'refreshData' })" tile dark dense>
            Refrescar
          </v-btn>
          <span v-if="last_movement && showMainPermiss('caja.options')">
            <v-btn v-for="(action, i) in last_movement.actions" :key="i" class="mx-2"
              :color="action.status_finish.color"
              @click="successAction({ action: action.accion, body: action })" tile dark dense>
              {{ action.status_finish.nombre }}
            </v-btn>
          </span>
        </v-row>
        <v-divider class="my-5" />
        <v-row dense>
          <v-col cols="6" sm="4" md="3" class="text-center" v-if="last_movement">
            <div class="font-weight-bold subtitle-2 black--text">Monto Base</div>
            <div class="font-weight-bold subtitle-2">
              $ {{ intlRound(last_movement.detalle.descripcion.monto) }}
            </div>
          </v-col>

          <v-col cols="6" sm="4" md="3" class="text-center">
            <div class="font-weight-bold subtitle-2 black--text">Disponible en Caja</div>
            <div class="font-weight-bold subtitle-2">
              $ {{ intlRound(totalIncome - totalEgress) }}
            </div>
          </v-col>

          <v-col cols="12" v-if="message !== ''">
            <div class="text-h3 text-uppercase font-weight-bold black--text text-center ma-auto">
              {{ message }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row dense>
      <v-col cols="12" class="py-5">
        <v-toolbar color="green darken-1" dense dark>
          <span class="font-weight-bold text-md-h4 text-sm-h5  pr-2">$ {{intlRound(totalIncome)}} </span>
          <span class="font-weight-bold subtitle-1">Ingresos + Base</span>
        </v-toolbar>
        <DataTableMain :datatable="tableIngresos" :on-success="successAction" :items-action="tableAction" />
      </v-col>
      <v-col cols="12" class="py-5">
        <v-toolbar color="amber darken-2" dense dark>
          <span class="font-weight-bold text-md-h4 text-sm-h5 pr-2">$ {{intlRound(totalEgress)}}</span>
          <span class="font-weight-bold subtitle-1">Egresos</span>
        </v-toolbar>
        <DataTableMain :datatable="tableEgresos" :on-success="successAction" :items-action="tableAction" />
      </v-col>
    </v-row>
    <MainFormDialog v-if="get_dialogMain.dialog" :model="object" :params="paramsDialog" :on-success="ResponseObject" />
  </v-container>
</template>

<script>
import DataTableMain from '@/components/tables/DataTableMain'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MainFormDialog from '@/components/dialogs/MainFormDialog'
// import DatePicker from '@/components/fields/DatePicker'
import { NumberUtils } from '@/mixins/NumberMixin'
import { DatesUtils } from '@/mixins/datesUtilsMixin'
import { Permission } from '@/mixins/permissionMain'
import { UserSession } from '@/store/modules/config'

export default {
  name: 'DashboardView',
  components: {
    DataTableMain,
    MainFormDialog
  },
  mixins: [NumberUtils, DatesUtils, Permission],
  data: (mv) => ({
    caja: null,
    last_movement: null,
    totalIncome: 0,
    ingresosMain: [],
    totalEgress: 0,
    egresosMain: [],
    tipo_cierre: 'COMPLETO',
    showSelect: false,
    tableIngresos: {
      header: {
        options: [],
        titles: [
          { text: 'Usuario', value: 'user', class: 'black--text' },
          { text: 'Folio Venta', value: 'folio', class: 'black--text' },
          { text: 'Fecha', value: 'date', class: 'black--text' },
          { text: 'Descripcion', value: 'description', class: 'black--text' },
          { text: 'Forma Pago', value: 'way_to_pay', class: 'black--text' },
          // { text: 'Monto', value: 'amount', class: 'black--text' },
          { text: 'Monto', value: 'DinamicImporte', class: 'black--text', fieldMain: 'amount' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: []
      },
      footer: {
        paging: true
      }
    },
    tableEgresos: {
      modelMain: [],
      header: {
        options: [],
        titles: [
          { text: 'Usuario', value: 'user', class: 'black--text' },
          { text: 'Descripción', value: 'description', class: 'black--text' },
          { text: 'Fecha', value: 'date', class: 'black--text' },
          // { text: 'Monto', value: 'amount', class: 'black--text' },
          { text: 'Monto', value: 'DinamicImporte', class: 'black--text', fieldMain: 'amount' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: []
      },
      footer: {
        paging: true
      }
    },
    params: {},
    fieldDateInit: {
      field: 'textDatePicker',
      name: 'Fecha Inicio',
      nameid: 'fecha_init',
      default: true,
      cols: 3
    },
    message: '',
    paramsurl: '',
    formas_pago: []
  }),
  computed: {
    ...mapGetters(['get_urls', 'get_dialogMain', 'get_object', 'get_objectTwo'])
  },
  watch: {
    get_urls (val) {
      this.ExecuteUrlValidacion('GET/venta/cajas/abierto')
    },
    get_object (val) {
      if (val.message) {
        this.message = val.message
        this.tableIngresos.header.loading = false
        this.tableEgresos.header.loading = false
        return
      }
      const router = this.get_urls['GET/catalogo/formas-pago']
      this.GETObjectTwoService({ url: router })
      this.message = ''
      this.caja = val
    },
    get_objectTwo (val) {
      this.formas_pago = val
    },
    caja (value) {
      if (value) {
        this.last_movement = value.last_movement
        const income = value.last_movement.detalles.filter((item) => item.descripcion.descripcion && item.descripcion.status_id === 24)
        const egress = value.last_movement.detalles.filter((item) => item.descripcion.descripcion && item.descripcion.status_id === 25)
        this.totalIncome = 0
        this.ingresosMain = income.map(item => {
          this.totalIncome += (item.descripcion.pago) ? item.descripcion.pago.importe : item.descripcion.monto
          return {
            id: item.id,
            date: item.descripcion.fecha,
            folio: item.descripcion.folio,
            amount: (item.descripcion.pago) ? item.descripcion.pago.importe : item.descripcion.monto,
            user: item.descripcion.usuario.usuario,
            description: item.descripcion.descripcion,
            way_to_pay_id: (item.descripcion.forma_pago_id) ? item.descripcion.forma_pago.id : item.descripcion.pago.forma_pago.id,
            way_to_pay: (item.descripcion.forma_pago_id) ? item.descripcion.forma_pago.nombre : item.descripcion.pago.forma_pago.nombre
          }
        })
        this.tableIngresos.body.data = []
        this.tableIngresos.body.data.push(...this.ingresosMain)
        this.totalIncome += parseFloat(this.last_movement.detalle.descripcion.monto)

        this.totalEgress = 0
        this.egresosMain = egress.map(item => {
          this.totalEgress += parseFloat(item.descripcion.monto)
          return {
            id: item.id,
            amount: parseFloat(item.descripcion.monto),
            description: item.descripcion.descripcion,
            date: item.descripcion.fecha,
            user: item.descripcion.usuario.usuario
          }
        })
        this.tableEgresos.body.data = []
        this.tableEgresos.body.data.push(...this.egresosMain)
        this.tableIngresos.header.loading = false
        this.tableEgresos.header.loading = false
      }
    }
  },
  mounted () {
    this.usuario = UserSession()
    this.paramsurl = `?usuario=${this.usuario.id}`
    this.ExecuteUrlValidacion('GET/venta/cajas/abierto')
    this.tableIngresos.header.loading = true
    this.tableEgresos.header.loading = true
  },
  methods: {
    ...mapActions(['GETUrlServices', 'POSTListObjectsService', 'GETObjectService', 'GETObjectTwoService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'CLEAR_LIST_DATA']),
    showMainPermiss (permiso) {
      return this.ReadOnlyPermiso(JSON.parse(localStorage.getItem('permisos')), permiso)
    },

    ExecuteUrlValidacion (url) {
      if (this.get_urls != null) {
        const router = this.get_urls[url]
        this.GETObjectService({ url: router, params_url: this.paramsurl })
      } else {
        this.GETUrlServices()
      }
    },
    actionFieldData (obj) {
      this.params[obj.id] = obj.data === undefined ? null : obj.data
      this.tableIngresos.header.loading = true
      this.tableEgresos.header.loading = true
      this.searchCaja()
    },
    ResponseObject ({ name, model }) {
      if (name === 'cierre-caja') {
        this.last_movement = null
        this.totalIncome = 0
        this.totalEgress = 0
        this.tableIngresos.body.data = []
        this.tableEgresos.body.data = []
        // this.successAction({ action: 'refreshData' })
      } else if (name === 'in-amount') {
        this.tableIngresos.body.data = []
        this.tableEgresos.body.data = []
        this.successAction({ action: 'refreshData' })
      } else if (name === 'out-amount') {
        this.tableIngresos.body.data = []
        this.tableEgresos.body.data = []
        this.successAction({ action: 'refreshData' })
      }
    },
    successAction (item) {
      this[item.action](item)
    },
    refreshData (item) {
      this.ExecuteUrlValidacion('GET/venta/cajas/abierto')
      this.tableIngresos.header.loading = true
      this.tableEgresos.header.loading = true
    },
    // === === === === === === === === === === \\
    tableAction (item) {
      this[item.action](item)
    },
    toDeposit (item) {
      item.item = this.caja
      this.object = item
      this.paramsDialog = {
        url: this.get_urls['POST/venta/cajas/execute'],
        message: 'Registro Agregado Correctamente',
        returnObject: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'in-amount', form: 'process', with: 650, title: 'Deposito Caja'})
    },
    toWithdraw (item) {
      const montoBase = parseFloat(this.last_movement.detalle.descripcion.monto)
      const totalIncome = parseFloat(this.totalIncome)
      const totalEgress = parseFloat(this.totalEgress)
      const obj = { ingresos: (totalIncome + montoBase), egresos: totalEgress }
      item.item = this.caja
      this.object = item
      this.paramsDialog = {
        url: this.get_urls['POST/venta/cajas/execute'],
        message: 'Registro Agregado Correctamente',
        returnObject: true,
        setmodel: true,
        data: obj
      }
      this.SHOW_DIALOG_MAIN({
        type: 'out-amount', form: 'process', with: 650, title: 'Retiro de Caja'
      })
    },
    toBalance (item) {
      if (this.tipo_cierre) {
        const ingresos = this.tableIngresos.body.data
        const egresos = this.tableEgresos.body.data
        if (ingresos.length <= 0) {
          this.$swal({
            type: 'warning',
            icon: 'warning',
            title: 'Advertencia !',
            text: 'La caja no puede cerrar sin un ingreso agregado',
          })
          return
        }
        const montos = this.SumaImportesCorte(ingresos, egresos)
        const base = parseFloat(this.last_movement.detalle.descripcion.monto)
        this.formas_pago.map(fp => {
          const nombre = fp.nombre.toLowerCase().replace(' ','_')
          if (!montos[nombre]) montos[nombre] = 0
          if (fp.id == 1){
            montos[nombre] += base
            montos[nombre] -= montos.retiros
          }
        })
        let obj = { importe_retiros: montos.retiros, montos: montos,tipo_cierre: this.tipo_cierre, ingresos: ingresos, egresos: egresos, 
          '10_centavos': 0, '20_nomeda': 0,'20_billete': 0,'50_nomeda': 0,'50_billete': 0,'1_nomeda': 0,'100_billete': 0,
          '2_nomeda': 0,'200_billete': 0,'5_nomeda': 0,'500_billete': 0,'10_nomeda': 0,'1000_billete': 0
        }
        item.item = this.caja
        this.object = item;
        this.paramsDialog = {
          url: this.get_urls['POST/venta/cajas/execute'],
          message: 'Registro Agregado Correctamente',
          returnObject: true,
          setmodel: true,
          data: obj
        };
        this.SHOW_DIALOG_MAIN({ type: 'cierre-caja', form: 'process', with: 1000, title: 'Cerrar Caja' });
      } else  {
        this.$swal({
          type: 'warning',
          icon: 'warning',
          title: 'Advertencia !',
          text: 'Es neesario seleccionar tipo de corte de caja.! ',
        })
      }
    },
    SumaImportesCorte (rows, rowse) {
      // console.log('rows', rows)
      let globales = { retiros: 0 }
      rows.map(row => {
        const nombre = row.way_to_pay.toLowerCase().replace(' ','_')
        if(globales[nombre]) globales[nombre] += row.amount
        else globales[nombre] = row.amount
      })

      rowse.map(rowe => { globales.retiros += rowe.amount })
      return globales
    },
    searchCaja () {
      const inicio = this.datetimeStringToDate(`${this.params.fecha_init} 00:00:00`)
      const final = this.datetimeStringToDate(`${this.params.fecha_init} 23:59:59`)
      const rows = []
      this.totalIncome = 0
      // eslint-disable-next-line array-callback-return
      this.ingresosMain.map(row => {
        const fecha = this.datetimeStringToDate(row.date)
        if (fecha.getTime() >= inicio.getTime() && fecha.getTime() <= final.getTime()) {
          this.totalIncome += row.amount
          rows.push(row)
        }
      })
      this.tableIngresos.body.data = []
      this.tableIngresos.body.data.push(...rows)

      this.tableIngresos.header.loading = false
      this.tableEgresos.header.loading = false
    },
    // === === === === === === === === === === \\
    // === === === === === === === === === === \\
    changeCierreCaja (selection) {
      if (selection) {
        this.showSelect = (selection === 'PARCIAL')
        if (selection === 'COMPLETO') {
          this.tableIngresos.body.data = []
          this.tableIngresos.body.data.push(...this.ingresosMain)
        }
      }
    }
  }
}
</script>

<style>

</style>
