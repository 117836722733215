export default ({
  state: {
    TipoFormaPagoForm: [
      { field: 'text', type: 'text', name: 'Nombre', nameid: 'nombre', rules: true, cols: 12 }
    ],
    TipoPreciosForm: [
      { field: 'text', type: 'text', name: 'Nombre', nameid: 'nombre', rules: true, cols: 12 },
      { field: 'text', type: 'text', name: 'Clave', nameid: 'slug', rules: true, cols: 12 },
      { field: 'radioButton', name: 'Tipo de Precio', nameid: 'tipo', items: [{ name: 'Entrada', value: 'in' }, { name: 'Salida', value: 'out' }], row: true, cols: 12 }
    ],
    MarcaForm: [
      { field: 'text', type: 'text', name: 'Nombre', nameid: 'nombre', rules: true, cols: 12 }
      // { field: 'selectDataServer', name: 'Empresa', nameid: 'empresa_id', url: '/empresa', rules: true, cols: 12 },
    ],
    UnidadMedidaForm: [
      { field: 'text', type: 'text', name: 'Nombre', nameid: 'nombre', rules: true, cols: 12 }
    ],
    FamiliaForm: [
      { field: 'text', type: 'text', name: 'Nombre', nameid: 'nombre', rules: true, cols: 12 }
    ],
    TiposDatosForm: [
      { field: 'text', type: 'text', name: 'Nombre', nameid: 'nombre', rules: true, cols: 12 },
      { field: 'textArea', name: 'Configuracion', nameid: 'default', dimens: 'input-7-1', rules: true, cols: 12 },
      { field: 'selectDataServer', name: 'Empresa', nameid: 'empresa_id', url: '/empresa', rules: true, cols: 12 }
    ],
    CaracteristicasForm: [
      { field: 'text', type: 'text', name: 'Nombre', nameid: 'nombre', rules: true, cols: 12 },
      { field: 'selectDataServer', name: 'Tipo de Dato', nameid: 'tipo_dato_id', url: '/catalogo/tipo-datos', rules: true, cols: 12 }
    ],
    TiposImpuestosForm: [
      { field: 'text', type: 'text', name: 'Nombre', nameid: 'nombre', rules: true, cols: 12 },
      { field: 'textArea', name: 'Configuracion', nameid: 'configuracion', dimens: 'input-7-1', rules: true, cols: 12 }
    ],
    FamiliaCaracterisiricForm: [
      { field: 'selectDataServer', name: 'Caracteristica', nameid: 'caracteristica_id', url: '/catalogo/caracteristicas', rules: true, cols: 12 },
      { field: 'text', type: 'text', name: 'Orden', nameid: 'order', rules: true, cols: 12 }
    ],
    SearchProductsForm: [
      { field: 'selectDataServer', name: 'Almacen', nameid: 'almacen_id', objectname: 'almacen', url: '/inventario/almacenes', value: 'id', action: 'ShowFieldDinamic', isHidden: ['articulo_id'], rules: true, cols: 12 }, // action: 'ShowFieldDinamic', isHidden: ['producto_id'],
      // { field: 'searchDataServer', name: 'Buscar Producto', nameid: 'producto_id', objectname: 'producto', url: '/catalogo/productos/find', method: true, params: {}, value: 'id', action: 'ShowHiddenFieldDataDinamic', isHidden: ['articulo_id', 'prices'], applicate: ['precio_unitario'], hidden: true, rules: true, cols: 6 },
      // { field: 'selectDataServer', name: 'Articulo', nameid: 'articulo_id', objectname: 'articulo', action: 'ShowHiddenFieldDataDinamic', item: [], isHidden: ['prices', 'udm_id'], applicate: ['precio_unitario'], value: 'id', hidden: false, rules: true, cols: 6 },
      { field: 'searchDataServer', name: 'Articulo', nameid: 'articulo_id', objectname: 'articulo', url: '/inventario/articulos/general/find', action: 'ShowHiddenFieldDataDinamic', item: [], isHidden: ['prices', 'udm_id', 'tipo_impuesto_id'], applicate: ['precio_unitario'], value: 'id', method: true, params: {}, hidden: false, rules: true, cols: 12 },
      // { field: 'selectDataServer', name: 'Tipo Unidad', nameid: 'udm_id', objectname: 'udm', url: '/catalogo/udm', action: 'ShowHiddenFieldDataDinamic', value: 'id', rules: true, hidden: true, cols: 4 },
      { field: 'number', type: 'number', name: 'Cantidad', nameid: 'cantidad', rules: true, cols: 4 },
      { field: 'number', type: 'number', name: 'Precio Unitario', nameid: 'precio_unitario', rules: true, cols: 4 },
      { field: 'selectDataServer', name: 'Tipo Impuesto', nameid: 'tipo_impuesto_id', objectname: 'tipo_impuesto', url: '/catalogo/tipos-impuestos', value: 'id', hidden: true, rules: true, cols: 4 },
      { field: 'showtableDataServer', name: 'Precios de Articulo', nameid: 'prices', hidden: true,
        titles: [
          { text: 'Nombre', value: 'nombre', class: 'black--text' },
          { text: 'Tipo', value: 'tipo', class: 'black--text' },
          { text: 'Precio', value: 'ChangepricesArticle', class: 'black--text', fieldMain: 'precio' }
        ],
        color_row: false,
        cols: 12
      }
    ],

    UpdateProductForm: [
      { field: 'searchDataServer', name: 'Articulo', nameid: 'articulo_id', objectname: 'articulo', url: '/inventario/articulos/general/find', action: 'ShowHiddenFieldDataDinamic', item: [], value: 'id', readonly: true, method: true, params: {}, rules: true, cols: 12 },
      { field: 'number', type: 'number', name: 'Cantidad', nameid: 'cantidad', rules: true, cols: 4 },
      { field: 'number', type: 'number', name: 'Precio Unitario', nameid: 'precio_unitario', rules: true, cols: 4 },
      { field: 'selectDataServer', name: 'Tipo Impuesto', nameid: 'tipo_impuesto_id', objectname: 'tipo_impuesto', url: '/catalogo/tipos-impuestos', value: 'id', rules: true, cols: 4 },
      { field: 'showtableDataServer', name: 'Precios de Articulo', nameid: 'prices',
        titles: [
          { text: 'Nombre', value: 'nombre', class: 'black--text' },
          { text: 'Tipo', value: 'tipo', class: 'black--text' },
          { text: 'Precio', value: 'ChangepricesArticle', class: 'black--text', fieldMain: 'precio'  }
        ],
        color_row: false,
        cols: 12
      },
    ],

    SearchSaleform: [
      { field: 'tableDataServer', name: 'Buscar ventas', nameid: 'sale', url: '/venta', post: true, data: { last_estatus: 16 },
        titles: [
          { text: 'Folio', value: 'folio', class: 'black--text' },
          { text: 'Total', value: 'total_amount', class: 'black--text' },
          { text: 'Cliente', value: 'cliente.NombreCompleto', class: 'black--text' },
          { text: 'Fecha de creacion', value: 'created_date', class: 'black--text' },
          { text: 'Estatus', value: 'Status', class: 'black--text' },
          { text: 'Activo', value: 'Activo', class: 'black--text' }
        ],
        color_row: false,
        cols: 12 }
    ],
    SearchArticleform: [
      { field: 'tableDataServer', name: 'Buscar ventas', nameid: 'article', items: [], 
        titles: [
          { text: 'Unidad', value: 'DinamicObject', class: 'black--text', fieldMain: 'udm.nombre', type: 'chip' },
          { text: 'Codigo', value: 'caracteristicas.codigo', class: 'black--text' },
          { text: 'Nombre', value: 'caracteristicas.descripcion', class: 'black--text' },
          { text: 'Familia', value: 'producto.caracteristicas.familia.nombre', class: 'black--text' },
          { text: 'Disponible', value: 'DinamicObject', class: 'black--text', align: 'center', fieldMain: 'inventarios.picking', type: 'textcenter' },
          // { text: 'Precio', value: 'DinamicObject', class: 'black--text', fieldMain: 'precio.precio', type: 'number' }
        ],
        color_row: true,
        cols: 12 }
    ],
    QuantityArticleform: [
      { field: 'viewText', name: 'Codigo', nameid: 'codigo', classMain: 'subtitle-1', cols: 6 },
      { field: 'viewText', name: 'Articulo', nameid: 'descripcion', classMain: 'subtitle-1', cols: 6 },
      { field: 'viewText', name: 'Disponible', nameid: 'disponible', classMain: 'subtitle-1', cols: 12 },
      { field: 'radioButton', name: '¿ Sumar Cantidades ?', nameid: 'add', items: [{ name: 'SI', value: true },{ name: 'NO', value: false }], row: true, cols: 12 },
      { field: 'number', type: 'number', name: 'Cantidad', nameid: 'cantidad', rules: true, cols: 12 }
    ],

    Clientesform: [
      { field: 'text', type: 'text', name: 'Nombres', nameid: 'nombres', rules: true, cols: 6 },
      { field: 'text', type: 'text', name: 'Apellidos', nameid: 'apellidos', rules: true, cols: 6 },
      { field: 'text', type: 'text', name: 'RFC', nameid: 'rfc', rules: true, cols: 6 },
      { field: 'text', type: 'text', name: 'CURP', nameid: 'curp', cols: 6 },

      { field: 'text', type: 'text', name: 'Telefono', nameid: 'telefono', rules: true, cols: 6 },
      { field: 'text', type: 'text', name: 'Dirección', nameid: 'direccion', rules: true, cols: 12 },      
      { field: 'text', type: 'text', name: 'CP', nameid: 'cp', rules: true, cols: 4 },
      { field: 'text', type: 'text', name: 'Colonia', nameid: 'colonia', rules: true, cols: 8 },
      { field: 'text', type: 'text', name: 'Ciudad', nameid: 'ciudad', rules: true, cols: 6 },
      { field: 'text', type: 'text', name: 'Estado', nameid: 'estado', rules: true, cols: 6 },

      { field: 'radioButton', name: 'Enviar Factura por Correo', nameid: 'enviar_factura', items: [{ name: 'SI', value: 'si' },{ name: 'NO', value: 'no' }], row: true, cols: 12 },
      { field: 'textEmail', type: 'email', name: 'Correo Electronico', nameid: 'email', cols: 12 }
    ],
    Proveedorform: [
      { field: 'textDatePicker', name: 'Fecha Alta', nameid: 'fecha_alta', default: true, cols: 6 },
      { field: 'text', type: 'text', name: 'Nombre Proveedor', nameid: 'nombre', rules: true, cols: 12 },
      { field: 'text', type: 'text', name: 'RFC', nameid: 'rfc', rules: true, cols: 12 },
      { field: 'text', type: 'text', name: 'Direccion', nameid: 'direccion', rules: true, cols: 12 },

      { field: 'text', type: 'email', name: 'Correo', nameid: 'email', rules: true, cols: 6 },
      { field: 'text', type: 'text', name: 'Telefono', nameid: 'telefono', rules: true, cols: 6 },
    ],
    PrecioArticuloForm: [
      { field: 'number', type: 'number', name: 'Precio', nameid: 'precio', rules: true, cols: 12 },
      { field: 'selectDataServer', name: 'Tipo Precio', nameid: 'tipo_precio_id', objectname: 'tipo_precio', url: '/inventario/tipos-precios', value: 'id', rules: true, cols: 12 },
      { field: 'textDatePicker', name: 'Vigencia', nameid: 'vigencia', cols: 12 },
    ],
    ProductosForm: [
      { field: 'selectGroupDataServer', name: 'Familia-SubFamilia', nameid: 'familiaId', url: '/catalogo/familias/subfamilias', sub: 'sub_familia', cols: 12 },
      { field: 'text', type: 'text', name: 'Nombre', nameid: 'nombre', rules: true, cols: 12 }
    ],
    AlmacenesForm: [
      { field: 'text', type: 'text', name: 'Nombre', nameid: 'nombre', rules: true, cols: 12 },
      { field: 'selectDataServer', name: 'Sucursal', nameid: 'sucursal_id', objectname: 'sucursal',value: 'id', url: '/empresa/sucursales', rules: true, cols: 12 }
    ],
    SucursalesForm: [
      { field: 'text', type: 'text', name: 'Nombre', nameid: 'nombre', rules: true, cols: 12 },

      { field: 'text', type: 'text', name: 'Razon Social', nameid: 'razon_social', rules: true, cols: 6 },
      { field: 'text', type: 'text', name: 'RFC', nameid: 'rfc', rules: true, cols: 6 },

      { field: 'messageViewText', name: 'Dirección Sucursal', classMain: 'title font-weight-bold black--text', 'cols': 12 },
      { field: 'text', type: 'text', name: 'Calle', nameid: 'calle', rules: true, cols: 3 },
      { field: 'text', type: 'text', name: 'Numero', nameid: 'numero', rules: true, cols: 3 },
      { field: 'text', type: 'text', name: 'Cruzamiento', nameid: 'cruzamiento', rules: true, cols: 3 },
      { field: 'text', type: 'text', name: 'Codigo postal', nameid: 'cp', rules: true, cols: 3 },

      { field: 'text', type: 'text', name: 'Estado', nameid: 'estado', rules: true, cols: 4 },
      { field: 'text', type: 'text', name: 'Municipio', nameid: 'municipio', rules: true, cols: 4 },
      { field: 'text', type: 'text', name: 'Colonia', nameid: 'colonia', rules: true, cols: 4 },

      { field: 'messageViewText', name: 'Numero de Contacto', classMain: 'title font-weight-bold black--text', 'cols': 12 },
      { field: 'text', type: 'text', name: 'Telefono', nameid: 'telefono', rules: true, cols: 6 }
    ],

    ArticuloFom: [
      { field: 'selectDataServer', name: 'Almacen', nameid: 'almacen_id', objectname: 'almacen', url: '/inventario/almacenes', value: 'id', rules: true, cols: 12 },
      { field: 'selectDataServer', name: 'Tipo Unidad', nameid: 'udm_id', objectname: 'udm', url: '/catalogo/udm', value: 'id', rules: true, cols: 6 },
      { field: 'text', type: 'number', name: 'Cantidad Minima', nameid: 'cantidad_minima', rules: true, cols: 6 },

      { field: 'radioButton', name: '¿ Es Servicio ?', nameid: 'isservicio', items: [{name: 'SI', value: true},{name: 'NO', value: false}], row: true, cols: 12 },

      { field: 'selectDataServer', name: 'Tipo Impuesto', nameid: 'tipo_impuesto_id', objectname: 'tipo_impuesto', url: '/catalogo/tipos-impuestos', value: 'id', rules: true, cols: 6 },
      { field: 'messageViewText', name: 'Equivalencia del Articulo', 'classMain': 'text-center ma-auto title', 'cols': 12 },
      { field: 'selectDataServer', name: 'Unidad Equivalente', nameid: 'udm_equivalente_id', objectname: 'udm_equivalente', url: '/catalogo/udm', value: 'id', rules: true, cols: 6 },

      { field: 'number', type: 'number', name: 'Cantidad Equivalente', nameid: 'equivalente', rules: true, cols: 6 },
      { field: 'text', type: 'text', name: 'Codigo Barras', nameid: 'codigo', rules: true, cols: 12 },
      { field: 'text', type: 'text', name: 'Descripcion Articulo', nameid: 'descripcion', rules: true, cols: 12 }
    ],

    UserForm: [
      { field: 'text', type: 'text', name: 'Nombre Usuario', nameid: 'usuario', rules: true, cols: 12 },
      { field: 'searchDataServer', name: 'Empleado', nameid: 'empleado_id', objectname: 'empleado', url: '/empresa/empleado/find', value: 'id', rules: true, cols: 12 },
      { field: 'text', type: 'text', name: 'Correo Electronico', nameid: 'email', rules: true, cols: 12 },
      { field: 'text', type: 'password', name: 'Contraseña', nameid: 'password', rules: true, cols: 12 },
      { field: 'text', type: 'password', name: 'Confirmar Contraseña', nameid: 'confirmpassword', rules: true, cols: 12 }
    ],
    UserUpdateForm: [
      { field: 'text', type: 'text', name: 'Nombre Usuario', nameid: 'usuario', rules: true, cols: 12 },
      { field: 'searchDataServer', name: 'Empleado', nameid: 'empleado_id', objectname: 'empleado', url: '/empresa/empleado/find', value: 'id', rules: true, cols: 12 },
      { field: 'text', type: 'text', name: 'Correo Electronico', nameid: 'email', rules: true, cols: 12 },
    ],
    PasswordUpdateForm: [
      { field: 'text', type: 'password', name: 'Nueva Contraseña', nameid: 'new_password', rules: true, cols: 12 },
      { field: 'text', type: 'password', name: 'Confirmar Contraseña', nameid: 'other_new_password', rules: true, cols: 12 }
    ],
    AssignRoles: [
      { field: 'viewText', name: 'Nombre Colaborador', nameid: 'NombreCompleto', cols: 12 },
      { field: 'viewText', name: 'Nombre Usuario', nameid: 'usuario', cols: 12 },
      { field: 'selectDataServer', name: 'Rol', nameid: 'rol_id', url: '/empresa/accesos/roles/show', post: true, rules: true, cols: 12 }
    ],
    AssignSucursal: [
      { field: 'viewText', name: 'Nombre Colaborador', nameid: 'NombreCompleto', cols: 12 },
      { field: 'viewText', name: 'Nombre Usuario', nameid: 'usuario', cols: 12 },
      { field: 'selectDataServer', name: 'Sucursal', nameid: 'sucursal_id', objectname: 'sucursal', value: 'id', url: '/empresa/sucursales', action: 'ShowFieldDinamic', isHidden: ['default', 'almacenes'], rules: true, cols: 12 },
      { field: "selectDataServer", name: "Almacen Principal", nameid: "default", url: "", url_default: "/inventario/almacenes/list-sucursal", rules: true, hidden: true, cols: 6 },
      { field: "selectDataServer", name: "Almacenes", nameid: "almacenes", url: "", url_default: "/inventario/almacenes/list-sucursal", multiple: true, rules: true, hidden: true, cols: 6 },
    ],
    RoleForm: [
      { field: 'text', type: 'text', name: 'Clave Role', nameid: 'slug', rules: true, cols: 12 },
      { field: 'text', type: 'text', name: 'Nombre Role', nameid: 'nombre', rules: true, cols: 12 }
    ],
    PermisosForm: [
      { field: 'text', type: 'text', name: 'Nombre', nameid: 'nombre', rules: true, cols: 12 },
      { field: 'text', type: 'text', name: 'Clave permiso', nameid: 'slug', rules: true, cols: 12 },
      { field: 'textArea', name: 'descripción', nameid: 'descripcion', dimens: 'input-7-1', rules: true, cols: 12 }
    ],
    EmpleadoForm: [
      { field: 'text', type: 'text', name: 'Clave empleado', nameid: 'cve_empleado', rules: true, cols: 4 },
      { field: 'text', type: 'text', name: 'Nombres', nameid: 'nombres', rules: true, cols: 12 },
      { field: 'text', type: 'text', name: 'Apellidos', nameid: 'apellidos', rules: true, cols: 12 },
      { field: 'selectDataServer', name: 'Sucursal', nameid: 'sucursal_id', objectname: 'sucursal',value: 'id', url: '/empresa/sucursales', rules: true, cols: 12 }
    ],
    RangeDateTimeForm: [
      { field: 'textDatePicker', name: 'Fecha Inicio', nameid: 'fecha_inicio', action:'ShowFieldDinamic', isHidden: ['ruta'], default: true, cols: 6 },
      { field: 'textDatePicker', name: 'Fecha Final', nameid: 'fecha_final', action:'ShowFieldDinamic', isHidden: ['ruta'], default: true, cols: 6 },
      { field: 'radioButton', name: 'Tipo reporte', nameid: 'tipo_reporte', items: [{ name: 'PDF', value: 'pdf' },{ name: 'Excel', value: 'excel' }], action:'ShowFieldDinamicSimple', isHidden: ['ruta'], row: true, cols: 12 },
      { field: 'previewpdf', nameid: 'ruta', params: 'data', name: 'PDF', cols: 12, hidden: true, route: '/venta/rpt/general/pdf' }
    ],
    SearchVentasForm: [
      { field: 'textDatePicker', name: 'Fecha Inicio', nameid: 'fecha_inicio', action:'ShowFieldDinamic', isHidden: ['ruta'], default: true, cols: 6 },
      { field: 'textDatePicker', name: 'Fecha Final', nameid: 'fecha_final', action:'ShowFieldDinamic', isHidden: ['ruta'], default: true, cols: 6 },
      { field: 'radioButton', name: 'Facturado', nameid: 'facturado', items: [{ name: 'SI', value: 'si' },{ name: 'NO', value: 'no' },{ name: 'AMBOS', value: 'todo' }], row: true, cols: 4 },
      { field: 'radioButton', name: 'Generar Bultos', nameid: 'bultos', items: [{ name: 'SI', value: 'si' },{ name: 'NO', value: 'no' }], row: true, cols: 4 },
      { field: 'radioButton', name: 'Tipo reporte', nameid: 'tipo_reporte', items: [{ name: 'PDF', value: 'pdf' },{ name: 'Excel', value: 'excel' }], action:'ShowFieldDinamicSimple', isHidden: ['ruta'], row: true, cols: 4 },
      { field: 'previewpdf', nameid: 'ruta', params: 'data', name: 'PDF', cols: 12, hidden: true, route: '/venta/rpt/facturados/pdf' }
    ],
    SearchComprasForm: [
      { field: 'textDatePicker', name: 'Fecha Inicio', nameid: 'fecha_inicio', action: 'ShowFieldDinamic', isHidden: ['ruta'], default: true, cols: 6 },
      { field: 'textDatePicker', name: 'Fecha Final', nameid: 'fecha_final', action: 'ShowFieldDinamic', isHidden: ['ruta'], default: true, cols: 6 },
      { field: 'radioButton', name: 'Facturado', nameid: 'facturado', items: [{ name: 'SI', value: 'si' },{ name: 'NO', value: 'no' },{ name: 'AMBOS', value: 'todo' }], row: true, cols: 4 },
      { field: 'radioButton', name: 'Tipo reporte', nameid: 'tipo_reporte', items: [{ name: 'PDF', value: 'pdf' },{ name: 'Excel', value: 'excel' }], action: 'ShowFieldDinamicSimple', isHidden: ['ruta'], row: true, cols: 4 },
      { field: 'previewpdf', nameid: 'ruta', params: 'data', name: 'PDF', cols: 12, hidden: true, route: 'compra/reportes/facturados-nofacturados' }
    ],
    SearchCajasForm: [
      { field: 'radioButton', name: 'Tipo reporte', nameid: 'tipo_reporte', items: [{ name: 'PDF', value: 'pdf' },{ name: 'Excel', value: 'excel' }], row: true, cols: 12 },
      { field: 'textDatePicker', name: 'Fecha Inicio', nameid: 'fecha_inicio', default: true, cols: 6 },
      { field: 'textDatePicker', name: 'Fecha Final', nameid: 'fecha_final', default: true, cols: 6 },
      // { field: 'radioButton', name: 'Todas Las Cajas', nameid: 'cajas', items: [{ name: 'SI', value: 'si' },{ name: 'NO', value: 'no' }], row: true, cols: 12 },
      { field: 'radioButton', name: '¿Agregar Detalles?', nameid: 'detalles', items: [{ name: 'SI', value: true },{ name: 'NO', value: false }], row: true, cols: 12 }
    ],
    UpdateAjusteInventarioForm: [
      { field: 'viewText', name: 'Sistema', nameid: 'sistema', classMain: 'title font-weight-bold black--text', cols: 12 },
      { field: 'number', type: 'number', name: 'Entrada', nameid: 'a_checkin', readonly: true, cols: 4 },
      { field: 'number', type: 'number', name: 'Bodega', nameid: 'a_stock', readonly: true, cols: 4 },
      { field: 'number', type: 'number', name: 'EnVenta', nameid: 'a_picking', readonly: true, cols: 4 },

      { field: 'number', type: 'number', name: 'Facturado', nameid: 'a_invoiced', readonly: true, cols: 4 },
      { field: 'number', type: 'number', name: 'No Facturado', nameid: 'a_unbilled', readonly: true, cols: 4 },

      { field: 'viewText', name: 'Importado', nameid: 'importado', classMain: 'title font-weight-bold black--text', cols: 12 },
      { field: 'number', type: 'number', name: 'Entrada', nameid: 'd_checkin', cols: 4 },
      { field: 'number', type: 'number', name: 'Bodega', nameid: 'd_stock', cols: 4 },
      { field: 'number', type: 'number', name: 'EnVenta', nameid: 'd_picking', cols: 4 },

      { field: 'number', type: 'number', name: 'Facturado', nameid: 'd_invoiced', cols: 4 },
      { field: 'number', type: 'number', name: 'No Facturado', nameid: 'd_unbilled', cols: 4 }
    ],
    ShowMessageDialog: [
      { field: 'messageViewText', name: '¿Desea Eliminar el Rol Asignado ?', classMain: 'text-center black--text ma-auto title', cols: 12 },
      { field: 'messageViewText', name: 'No podras revertir el movimiento !!', classMain: 'text-center ma-auto', cols: 12 }
    ],
    ShowMessageSucursalDialog: [
      { field: 'messageViewText', name: '¿Desea Eliminar la Sucursal Asignada ?', classMain: 'text-center black--text ma-auto title', cols: 12 },
      { field: 'messageViewText', name: 'No podras revertir el movimiento !!', classMain: 'text-center ma-auto', cols: 12 }
    ],
    ShowPdfticketDialog: [
      { field: 'showPdfDataServer', name: 'View pdf', nameid: 'pdf', name_pdf: 'Pdf Reporte', cols: 12 }
    ],
    ConfiguracionSistemaForm: [
      { field: 'text', type: 'text', name: 'Nombre', nameid: 'nombre', rules: true, cols: 12 },
      { field: 'textArea', name: 'Configuracion', nameid: 'ConfigString', dimens: 'input-7-1', rules: true, cols: 12 },
      { field: 'selectDataServer', name: 'Empresa', nameid: 'empresa_id', url: '/empresa', rules: true, cols: 12 }
    ],
    ShowDetailCajaForm: [
      { field: 'ShowDetailCaja', name: 'Detalle', nameid: 'detalles', url: '/venta/cajas/ingresos-egresos', fields: ['folio', 'id'], cols: 12 }
    ],
    ShowPreciosArticulos: [
      { field: 'selectDataServer', name: 'Tipo Unidad', nameid: 'udm_id', objectname: 'udm', url: '/catalogo/udm', value: 'id', action: 'ShowHiddenFieldDataDinamic', isHidden: ['pdf'], addicional: ['sucursal_id', 'almacen_id'], rules: true, cols: 6 },
      { field: 'showPdfDataServer', name: 'View pdf', nameid: 'pdf', name_pdf: 'Pdf Reporte', url: 'inventario/reportes/general-lista-precios', hidden: true, cols: 12 }
    ]
  },
  getters: {
    get_TipoFormaPagoForm (state) { return state.TipoFormaPagoForm },
    get_TipoPreciosForm (state) { return state.TipoPreciosForm },

    get_MarcaForm (state) { return state.MarcaForm },
    get_UnidadMedidaForm (state) { return state.UnidadMedidaForm },

    get_FamiliaForm (state) { return state.FamiliaForm },
    get_TiposDatosForm (state) { return state.TiposDatosForm },
    get_CaracteristicasForm (state) { return state.CaracteristicasForm },
    get_TiposImpuestosForm (state) { return state.TiposImpuestosForm },
    get_FamiliaCaracterisiricForm (state) { return state.FamiliaCaracterisiricForm },

    get_SearchProductsForm (state) { return state.SearchProductsForm },
    get_UpdateProductForm (state) { return state.UpdateProductForm },

    get_SearchSaleform (state) { return state.SearchSaleform },
    get_SearchArticleform (state) { return state.SearchArticleform },
    get_QuantityArticleform (state) { return state.QuantityArticleform },

    get_Clientesform (state) { return state.Clientesform },
    get_Proveedorform (state) { return state.Proveedorform },
    get_PrecioArticuloForm (state) { return state.PrecioArticuloForm },
    get_ProductosForm (state) { return state.ProductosForm },
    get_AlmacenesForm (state) { return state.AlmacenesForm },
    get_SucursalesForm (state) { return state.SucursalesForm },

    get_ArticuloFom (state) { return state.ArticuloFom },

    get_UserForm (state) { return state.UserForm },
    get_UserUpdateForm (state) { return state.UserUpdateForm },
    get_PasswordUpdateForm (state) { return state.PasswordUpdateForm },

    get_AssignRoles (state) { return state.AssignRoles },
    get_AssignSucursal (state) { return state.AssignSucursal },

    get_RoleForm (state) { return state.RoleForm },
    get_PermisosForm (state) { return state.PermisosForm },
    get_EmpleadoForm (state) { return state.EmpleadoForm },
    get_RangeDateTimeForm (state) { return state.RangeDateTimeForm },
    get_SearchVentasForm (state) { return state.SearchVentasForm },
    get_SearchComprasForm (state) { return state.SearchComprasForm },

    get_SearchCajasForm (state) { return state.SearchCajasForm },
    get_UpdateAjusteInventarioForm (state) { return state.UpdateAjusteInventarioForm },
    get_ShowMessageDialog (state) { return state.ShowMessageDialog },
    get_ShowMessageSucursalDialog (state) { return state.ShowMessageSucursalDialog },
    get_ShowPdfticketDialog (state) { return state.ShowPdfticketDialog },

    get_ConfiguracionSistemaForm (state) { return state.ConfiguracionSistemaForm },
    get_ShowDetailCajaForm (state) { return state.ShowDetailCajaForm },

    get_ShowPreciosArticulos (state) { return state.ShowPreciosArticulos }
  },
  mutations: {},
  actions: {}
})
