import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{staticClass:"py-3",attrs:{"dense":""}},_vm._l((_vm.directos),function(action,index){return _c(VCol,{key:index,staticClass:"px-4",class:action.hidden,attrs:{"cols":"3"}},[_c(VCard,{attrs:{"tile":""},on:{"click":function($event){return _vm.menu(action.action)}}},[_c(VImg,{staticClass:"white--text align-end",attrs:{"src":require("@/assets/logo.png"),"gradient":"to bottom, rgba(2,40,68,.83), rgba(9,65,107,.3)","max-height":"280px"}},[_c(VCardText,{staticClass:"align-center text-center"},[_c('div',{staticClass:"mb-4"},[_c(VBtn,{attrs:{"fab":"","color":action.color,"elevation":"0","x-large":"","dark":""}},[_c(VIcon,{attrs:{"x-large":""}},[_vm._v(_vm._s(action.icon))])],1)],1),_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline mb-1 title text-uppercase white--text font-weight-bold"},[_vm._v(_vm._s(action.title))]),_c(VListItemSubtitle,{staticClass:"white--text"},[_vm._v(_vm._s(action.message))])],1)],1)],1)],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }