<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-btn color="primary" class="mx-2" tile dark @click="generalSearch">
          <v-icon left> mdi-feature-search</v-icon> Buscar
        </v-btn>
        <v-btn v-if="sale" color="black" class="mx-2" tile dark @click="clearStates">
          <v-icon left> mdi-refresh</v-icon> Limpiar
        </v-btn>
        <span v-if="last_movement && showMainPermiss('venta.options')">
          <v-btn
            v-for="(action, i) in last_movement.actions"
            :key="i"
            class="mx-2"
            :color="action.status_finish.color"
            @click="successAction({ action: action.accion, body: action })"
            tile
            dark
          >
            {{ action.status_finish.nombre }}
          </v-btn>
        </span>
        <span v-if="!last_movement && showMainPermiss('venta.options.toGenerate')">
          <v-btn
            @click="successAction({ action: 'newData', body: {} })"
            color="success"
            class="mx-2"
            tile
            dark>
            <v-icon left> mdi-plus</v-icon> Terminar
          </v-btn>
        </span>
      </v-col>
      <v-col cols="12">
        <v-card tile :loading="loadingDataMain">
          <v-card-text>
            <v-row v-if="caja.id" dense>
              <v-col cols="12">
                <SearchDinamicField :item-action="SearchClienteField" :on-success="actionFieldData"/>
              </v-col>
              <v-col cols="3">
                <div>
                  <label class="black--text font-weight-bold title ma-0">Caja: </label>
                  <span class="font-weight-bold font-weight-black title">{{ caja.nombre }}</span>
                </div>
              </v-col>
              <v-col cols="3">
                <!-- v-if="showMainPermiss('ventas.change.tipo-precio')" -->
                <SelectedDinamicField :setter-model="object.tipo_precio_id" :item-action="SelectedTipoPrecioField" :on-success="actionFieldData" />
              </v-col>
              <v-col v-if="showMainPermiss('ventas.change.almacen')" cols="3">
                <SelectedDinamicField :setter-model="object.almacen_id" :item-action="SelectedAlmacenField" :on-success="actionFieldData" />
              </v-col>
              <v-col cols="3">
                <!-- v-if="showMainPermiss('ventas.change.select-udm')" -->
                <SelectedDinamicField :setter-model="object.unidades" :item-action="SelectedUnidadMedidaField" :on-success="actionFieldData" />
              </v-col>

              <v-col v-if="sale && sale.activo" cols="6">
                <div>
                  <label class="black--text font-weight-bold title ma-0">Folio venta: </label>
                  <span class="font-weight-bold font-weight-black title">{{ sale.folio }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="showTotales" dense>
              <v-col cols="4">
                <div class="text-center text-h5">
                  <p class="black--text font-weight-bold ma-0">Importe</p>
                  <span class="font-weight-bold font-weight-black"
                    >${{ intlRound(header.importe) }}</span
                  >
                </div>
              </v-col>
              <v-col cols="4">
                <div class="text-center text-h5">
                  <p class="black--text font-weight-bold ma-0">Impuesto</p>
                  <span class="font-weight-bold font-weight-black"
                    >${{ intlRound(header.impuesto) }}</span
                  >
                </div>
              </v-col>
              <v-col cols="4">
                <div class="text-center text-h5">
                  <p class="black--text font-weight-bold ma-0">Total</p>
                  <span class="font-weight-bold font-weight-black"
                    >${{ intlRound(header.total) }}</span
                  >
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-divider class="pa-2" />
              <v-col cols="12" v-if="showMainPermiss('inventario.articulos.find')">
                <v-text-field
                  :loading="table.header.loading"
                  v-model="codigo"
                  label="Buscar"
                  append-icon="mdi-magnify"
                  outlined
                  dense
                  @click:append="Searchproducto"
                  @keypress.enter="Searchproducto"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <DataTableMain :datatable="table" :on-success="successAction" :items-action="successAction" />
      </v-col>
    </v-row>
    <MainFormDialog v-if="get_dialogMain.dialog" :model="objectMain" :params="paramsDialog" :on-success="dataForm" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import MainFormDialog from "@/components/dialogs/MainFormDialog";
import SelectedDinamicField from "@/components/fields/SelectedDinamicField";
import SearchDinamicField from '@/components/fields/SearchDinamicField';
import DataTableMain from "@/components/tables/DataTableMain";
import { NumberUtils } from '@/mixins/NumberMixin'
import { Permission } from '@/mixins/permissionMain'
import { UserSession } from '@/store/modules/config'
export default {
  name: "VentaView",
  components: {
    MainFormDialog,
    DataTableMain,
    SelectedDinamicField,
    SearchDinamicField
  },
  mixins: [NumberUtils, Permission],
  data: () => ({
    loadingDataMain: false,
    paramsDialog: {},
    object: { facturar: false },
    objectMain: { },
    SelectedTipoPrecioField: {
      field: 'selectDataServer',
      name: 'Tipo de Precio',
      nameid: 'tipo_precio_id',
      url: '/inventario/tipos-precios',
      cols: 3,
    },
    SelectedAlmacenField: {
      field: 'selectDataServer',
      name: 'Almacen',
      nameid: 'almacen_id',
      url: '/inventario/almacenes/list-sucursal',
      parmas: '',
      cols: 3,
    },
    SelectedUnidadMedidaField: {
      field: "selectDataServer",
      name: "Unidades",
      nameid: "unidades",
      url: "/catalogo/udm",
      multiple: true,
      rules: true,
      cols: 6
    },
    SearchClienteField :{
      field: "searchDataServer",
      name: "Buscar Cliente",
      nameid: "cliente_id",
      url: "/venta/clientes/find",
      itemValue: "id",
      rules: true,
      cols: 12
    },
    item: null,
    form: null,
    codigo: "",
    caja: {},
    sale: null,
    header: {
      importe: 0,
      impuesto: 0,
      total: 0,
    },
    showTotales: true,
    table: {
      modelMain: [],
      header: {
        options: [],
        titles: [
          { text: "Codigo Barras", value: "articulo.caracteristicas.codigo", class: "black--text" },
          { text: "Producto", value: "articulo.caracteristicas.descripcion", class: "black--text" },
          { text: "Cantidad", value: "EditCantidad", class: "black--text" },
          { text: "Disponible", value: "articulo.inventarios.picking", class: "black--text" },
          { text: "Unidad", value: "articulo.udm.nombre", class: "black--text" },
          { text: "Precio", value: "articulo.precio.precio", class: "black--text" },
          { text: "SubTotal", value: "DinamicImporte", class: "black--text", fieldMain: 'subtotal' },
          { text: "Impuesto", value: "DinamicImporte", class: "black--text", fieldMain: 'impuesto' },
          { text: "Importe Total", value: "DinamicImporte", class: "black--text" , fieldMain: 'importe' },
          { text: "", value: "Actions", class: "black--text" },
        ],
        loading: false,
        showSearch: false,
      },
      body: {
        data: [],
        actions: [
          {
            title: "Eliminar",
            icon: "mdi-minus-circle",
            color: "error",
            action: "deleteItem",
            code: "venta.detalle.delete"
          },
        ],
      },
      footer: {
        paging: true,
      },
    },
    last_movement: null,
    events: {},
    tiposPrecios: [],
    cliente_id: null,
    paramsurl: '',
    usuario: {}
  }),
  mounted() {
    this.usuario = UserSession()
    this.paramsurl = `?usuario=${this.usuario.id}`
    this.loadingDataMain = true
    this.ExecuteOptionUrl("GET/inventario/tipos-precios", 2);

    this.ExecuteUrlValidacion("GET/venta/cajas/abierto");
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    ...mapActions([
      "GETOptionService",
      "GETUrlServices",
      "GETObjectTwoService",
      "GETListObjectsTwoService",
      "POSTObjectsThreeService",
      "POSTListObjectsService",
      "POSTExecuteService",
      "DELETEObjectTwoService",
      'GETObjectPdftService'
    ]),
    ...mapMutations(["SHOW_DIALOG_MAIN"]),
    showMainPermiss (permiso) {
      return this.ReadOnlyPermiso(JSON.parse(localStorage.getItem('permisos')), permiso)
    },
    actionFieldData (obj) {
      if (obj.id === 'cliente_id'){
        this[obj.id] = (obj.data) ? obj.data : null
      } else {
        this.object[obj.id] = (obj.data) ? obj.data : null
      }
    },
    successAction(item) {
      if(item.action == 'addArticulo'){
        this[item.action](item,true);
        return
      }
      this[item.action](item);
    },

    newData(item) {
      if (this.table.body.data.length <= 0) {
        this.$swal({
          type: "warning",
          icon: "warning",
          title: "Advertencia!",
          text: "Debe agregar almenos 1 producto.!!",
        });
        return;
      }

      this.objectMain = {
        caja_id: this.caja.id,
        sucursal_id: this.caja.caracteristicas.sucursal_id,
        almacen_id: this.caja.caracteristicas.almacen_id,
        tipo_precio_id: this.caja.caracteristicas.tipo_precio_id,
        facturar: false,
        cliente_id: this.cliente_id
        // articulos: this.table.body.data
      };
      this.paramsDialog = {
        urlprocess: this.get_urls["GET/venta/options[/{status_id}]"],
        message: "Registro Agregado Correctamente",
        setmodel: true,
        returnObject: true,
      };
      this.SHOW_DIALOG_MAIN({ type: "create", form: "process", with: 850, title: "Nuevo Pedido" });
    },

    toPending(item) {
      this.events = { eventPending: true };
      this.objectMain = item;
      this.paramsDialog = {
        urlprocess: this.get_urls["GET/venta/options[/{status_id}]"],
        message: "Registro Agregado Correctamente",
        data: { en_abonos: false, importe_pagar: this.header.total, forma_pago_id: 1 },
        setmodel: true,
        returnObject: true,
      };
      this.SHOW_DIALOG_MAIN({ type: "paid", form: "process", with: 850, title: "Pagar Venta" });
    },

    toCancel(item) {
      this.events = { eventCancel: true };
      this.objectMain = item;
      this.paramsDialog = {
        urlprocess: this.get_urls["GET/venta/options[/{status_id}]"],
        message: "Registro Agregado Correctamente",
        returnObject: true,
      };
      this.SHOW_DIALOG_MAIN({
        type: "cancelSale",
        form: "process",
        with: 850,
        title: "Cancelar Venta",
      });
    },
    dataForm({ name, model }) {
      let router = null;
      switch (name) {
        case "create":
          this.loadingDataMain = true
          model.articulos = this.table.body.data;
          model.header = this.header;
          model.caja_id = this.caja.id;
          router = this.get_urls["POST/venta/execute"];
          this.POSTExecuteService({ url: router, data: model });
          break;
        case "paid":
        case "cancelSale":
          this.loadingDataMain = true
          model.id = this.sale.id;
          router = this.get_urls["POST/venta/execute"];
          if (name === "paid") model.data = this.table.body.data;
          this.POSTExecuteService({ url: router, data: model });
          break;
        case "search":
          // console.log('search', model)
          this.setSale(model.sale);
          break;
        case "search_article":
          // console.log(model)
          if (model.article.inventarios.picking || model.article.inventarios.picking > 0)
            this.addArticulo(model.article,true)
          else {
            if(model.article.ArtDesarmar) {
              const desarmar = model.article.ArtDesarmar
              this.$swal.fire({
                title: 'Articulo no cuenta con disponible de venta',
                text: `¿ Decea aperturar el ${desarmar.udm.nombre} de ${desarmar.caracteristicas.descripcion}, Disponible ${desarmar.inventarios.picking} ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Abrir !',
                cancelButtonText: 'No, Cerrar'
              }).then((result) => {
                if (result.value) {
                  // agregar el metodo para apertura de bultos
                  this.DesarmarArticuloinventario(model.article)
                }
              })
            } else {
              this.$swal({ type: "warning", icon: "warning", title: "Advertencia !", text: 'Articulo sin disponible.', timer: 2000 });
            }
          }
          break
          case "sale_article_gramage":
            this.addArticulo(model.articulo,model.add,model.cantidad)
          break
      }
    },

    Searchproducto (e) {
      if (this.last_movement && this.last_movement.estatus.id === 16) return
      this.table.header.loading = true
      this.object.codigo = this.codigo
      const router = this.get_urls['POST/inventario/articulos/find']
      this.POSTListObjectsService({ url: router, data: this.object })
    },

    addArticulo(val,add,cantidad = 1) {
      if (this.last_movement && this.last_movement.estatus.id === 16) returns
      let desarmable = 0
      const article = this.table.body.data.find(
        (a) => a.articulo.id === ((val.element_action) ? val.item : val.id)
      );
      let articulosSave = {}
      if (article) {
        if(!val.element_action) article.articulo = val
        desarmable = article.articulo.udm.desarmable
        let cantidadfinal = (add)? (article.cantidad + cantidad) : cantidad
        if (!article.articulo.caracteristicas.isservicio) {
          if (article.articulo.inventarios.picking < cantidadfinal) {
            if(article.articulo.ArtDesarmar) {
              const desarmar = article.articulo.ArtDesarmar
              this.$swal.fire({
                title: 'Articulo no cuenta con disponible de venta',
                text: `¿ Decea aperturar el ${desarmar.udm.nombre} de ${desarmar.caracteristicas.descripcion}, Disponible ${desarmar.inventarios.picking} ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Abrir !',
                cancelButtonText: 'No, Cerrar'
              }).then((result) => {
                if (result.value) {
                  // agregar el metodo para apertura de bultos
                  this.DesarmarArticuloinventario(article.articulo)
                }
              })
            } else {
              this.$swal({
                type: "warning",
                icon: "warning",
                title: "Advertencia",
                text: 'La cantidad de compra no debe ser mayor al disponible'
              })
            }
            return
          }
        }
        article.cantidad = cantidadfinal;
        article.subtotal = article.articulo.precio.precio * article.cantidad;
        const impuesto = this.ExecuteForm({
          formula: article.articulo.impuesto.configuracion.formula,
          parametros: article.articulo.impuesto.configuracion.parametros,
          value: [article.subtotal],
        });
        article.impuesto = impuesto;
        article.importe = (article.subtotal + impuesto);
        articulosSave = article
      } else {
        if (val.inventarios.picking < cantidad) {
          if (val.ArtDesarmar) {
            const desarmar = val.ArtDesarmar
            this.$swal.fire({
              title: 'Articulo no cuenta con disponible de venta',
              text: `¿ Decea aperturar el ${desarmar.udm.nombre} de ${desarmar.caracteristicas.descripcion}, Disponible ${desarmar.inventarios.picking} ?`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Si, Abrir !',
              cancelButtonText: 'No, Cerrar'
            }).then((result) => {
              if (result.value) {
                // agregar el metodo para apertura de bultos
                this.DesarmarArticuloinventario(val)
              }
            })
          } else {
            this.$swal({
              type: "warning",
              icon: "warning",
              title: "Advertencia",
              text: 'La cantidad a comprar no debe ser mayor al disponible'
            })
          }
          return
        }
        desarmable = val.udm.desarmable
        const importe = val.precio.precio * cantidad;
        const impuesto = this.ExecuteForm({
          formula: val.impuesto.configuracion?.formula,
          parametros: val.impuesto.configuracion?.parametros,
          value: [importe],
        });
        articulosSave = {
          articulo: val,
          cantidad: cantidad,
          subtotal: importe,
          impuesto: impuesto,
          importe: (importe + impuesto),
        }
        this.table.body.data.unshift(articulosSave);
      }
      this.sale = true;
      this.totalCalculate();
      /* if (desarmable > 0) { this.SetQuantityArticleSale(articulosSave) } */
    },

    ExecuteOptionUrl(url, nivel = 1) {
      if (this.get_urls != null) {
        const router = this.get_urls[url];
        if (nivel === 2)
          this.GETListObjectsTwoService({ url: router, params: "/1" });
        else
          this.GETOptionService({ url: router, params: "/1" });
      } else {
        this.GETUrlServices();
      }
    },
    ExecuteUrlValidacion (url) {
      if (this.get_urls != null) {
        const router = this.get_urls[url]
        this.GETObjectTwoService({ url: router, params_url: this.paramsurl })
      } else {
        this.GETUrlServices()
      }
    },
    deleteItem (value) {
      if (this.last_movement && this.last_movement.estatus.id === 16) return

      if (this.sale?.activo && value.action === "deleteItem") {
        this.table.header.loading = true;
        const router = this.get_urls["DELETE/venta/detalles/{id}"];
        this.DELETEObjectTwoService({ url: router, params: value.item.id, replace: "{id}" });
      }

      if (value.element_action === "subtract_element") {
        let article = this.table.body.data.find(
          (item) => item.articulo.id === value.item.articulo.id
        );
        if (article.cantidad === 1)
          this.table.body.data = this.table.body.data.filter(
            (item) => item.articulo.id !== value.item.articulo.id
          );
        else {
          article.cantidad = article.cantidad - 1;
          article.subtotal = article.articulo.precio.precio * article.cantidad;
          const impuesto = this.ExecuteForm({
            formula: article.articulo.impuesto.configuracion.formula,
            parametros: article.articulo.impuesto.configuracion.parametros,
            value: [article.subtotal],
          });
          article.impuesto = impuesto;
          article.importe = (article.subtotal + impuesto);
        }
      } else if (!this.sale?.activo)
        this.table.body.data = this.table.body.data.filter(
          (item) => item.articulo.id !== value.item.articulo.id
        );
      if (this.table.body.data.length === 0 && !this.sale?.activo) this.clearStates();
      this.totalCalculate();
    },

    generalSearch() {
      this.paramsDialog = {
        returnObject: true,
        form: this.get_SearchSaleform,
      };
      this.SHOW_DIALOG_MAIN({ type: "search", with: 950, title: "Buscar ventas" });
    },

    setSale(value) {
      this.item = value;
      this.sale = value;
      this.last_movement = value.last_movement;
      this.table.body.data = value.detalles.map((item) => {
        return {
          id: item.id,
          articulo: item.articulo,
          cantidad: item.cantidad,
          subtotal: (item.cantidad * item.precio_unitario),
          impuesto: item.impuesto,
          importe: ((item.cantidad * item.precio_unitario) + item.impuesto),
        };
      });
      // this.object = {
      //   tipo_precio_id: value.caracteristicas.tipo_precio_id,
      //   almacen_id: value.caracteristicas.almacen_id,
      //   facturar: value.caracteristicas.facturar,
      // };
      this.totalCalculate();
    },

    clearStates () {
      this.sale = null
      this.codigo = ''
      this.events = { newSale: true }
      this.last_movement = null

      this.object.sucursal_id = this.caja.caracteristicas.sucursal_id
      this.object.almacen_id = this.caja.caracteristicas.almacen_id
      this.object.tipo_precio_id = this.caja.caracteristicas.tipo_precio_id

      this.header = { importe: 0, impuesto: 0, total: 0 }
      this.table.body.data = []
    },

    totalCalculate () {
      this.showTotales = false
      this.header.importe = 0
      this.header.impuesto = 0
      this.header.total = 0
      this.table.body.data.map((prod) => {
        this.header.importe += prod.subtotal
        this.header.impuesto += prod.impuesto
        this.header.total += prod.importe
      })
      this.showTotales = true
    },

    changeUdm (value) {
      let article = this.table.body.data.find(item=> item.articulo.id === value.item.article)
      //Generar el codigo para poder cambiar de precio y totales
    },
    SelectedArticleSearch(rows) {
      let articles = JSON.parse(JSON.stringify(this.get_SearchArticleform))
      articles.map(field => {
        if (field.field === 'tableDataServer') {
          this.tiposPrecios.map(tipoP => {
            if (tipoP.tipo === 'out'){
              const FieldPrecio = { text: tipoP.nombre, value: 'DinamicObjectList', class: 'black--text', fieldMainList: 'precios', fieldMain: 'precio', type: 'number' }
              field.titles.push(FieldPrecio)
            }
          })
        }
      })
      articles[0].items = rows
      this.paramsDialog = {
        returnObject: true,
        form: articles,
      };
      this.SHOW_DIALOG_MAIN({ type: "search_article", with: '80%', title: "Buscar ventas" });
    },
    SetQuantityArticleSale(item) {
      if(item.element_action){

        this.objectMain = {
          codigo: item.item.articulo.caracteristicas.codigo,
          descripcion: item.item.articulo.caracteristicas.descripcion,
          cantidad: item.item.cantidad,
          articulo: item.item.articulo,
          disponible: `${item.item.articulo.inventarios.picking} (${item.item.articulo.udm.nombre})`,
          add: false
        }
      } else {
        this.objectMain = {
          codigo: item.articulo.caracteristicas.codigo,
          descripcion: item.articulo.caracteristicas.descripcion,
          cantidad: item.cantidad,
          articulo: item.articulo,
          disponible: `${item.articulo.inventarios.picking} (${item.articulo.udm.nombre})`,
          add: false
        }
      }
      this.paramsDialog = {
        returnObject: true,
        form: this.get_QuantityArticleform,
        setmodel: true
      };
      this.SHOW_DIALOG_MAIN({ type: "sale_article_gramage", with: '40%', title: "Venta en Gramaje" });
    },
    DesarmarArticuloinventario(item) {
      const data = {
        unidad_origen: item.ArtDesarmar.udm_id,
        articulo_origen: item.ArtDesarmar.id,
        unidad_destino: item.udm_id,
        articulo_destino: item.id,
        cantidad: 1,
      }
      const url = this.get_urls['POST/venta/apertura-articulo']
      this.POSTObjectsThreeService({url: url, data: data })
    }
  },
  computed: {
    ...mapGetters([
      "get_urls",
      "get_dialogMain",
      "get_options",
      "get_objects",
      "get_objectsTwo",
      "get_objectTwo",
      "get_objectThree",
      "get_model",
      "get_SearchSaleform",
      "get_SearchArticleform",
      "get_QuantityArticleform",
      "get_objectDelete",
      "get_ShowPdfticketDialog",
      "get_objectPdf",
    ]),
  },
  watch: {
    get_urls(val) {
      this.ExecuteUrlValidacion("GET/venta/cajas/abierto");
    },
    get_objectTwo(val) {
      this.loadingDataMain = false
      if (val.message) {
        this.$swal({
          type: "error",
          icon: "error",
          title: "Error !",
          text: val.message,
          timer: 1500,
        });
        return;
      }
      if (val.last_movement.estatus.estatus == "abierto") {
        this.object = {
          sucursal_id: val.caracteristicas.sucursal_id,
          almacen_id: val.caracteristicas.almacen_id,
          tipo_precio_id: val.caracteristicas.tipo_precio_id,
          unidades: [1,4,5,8]
        }
        this.SelectedAlmacenField.params = `/${this.object.sucursal_id}`
        this.caja = val;
        this.events = { newSale: true };
      }

    },
    get_objectThree(val) {
      this.loadingDataMain = false
      if (val.message) {
        this.$swal({
          type: "error",
          icon: "error",
          title: "Error !",
          text: val.message,
          timer: 1500,
        });
        return;
      }
      this.table.header.loading = true;
      const router = this.get_urls["POST/inventario/articulos/find"];
      this.object.codigo = val.caracteristicas.codigo
      this.POSTListObjectsService({ url: router, data: this.object });
    },
    get_objects(val) {
      if (val.message) {
        this.$swal({
          type: "error",
          icon: "error",
          title: "Error !",
          text: val.message,
          timer: 1500,
        });
        return;
      }
      this.codigo = "";
      if (val.length <= 0) {
        this.$swal({
          type: "warning",
          icon: "warning",
          title: "Advertencia !",
          text: 'Articulo no encontrado.',
          timer: 1500,
        });
      this.table.header.loading = false;
        return;
      }
      if (val.length > 1) {
        this.SelectedArticleSearch(val)
      } else if (val.length === 1) {
        const unico = val[0]
        if (unico.inventarios.picking || unico.inventarios.picking > 0)
          this.addArticulo(val[0],true)
        else
          this.SelectedArticleSearch(val)
      }
      this.table.header.loading = false;
    },
    get_objectsTwo (val) {
      if (val) { this.tiposPrecios = val }
    },
    get_options(val) {
      if (val) {
        this.form = val;
      }
    },
    get_model(val) {
      this.loadingDataMain = false
      if (val.message) {
        this.$swal({
          type: "error",
          icon: "error",
          title: "Error !",
          text: val.message,
        })
        return
      }
      this.sale = val;
      if (this.events.newSale) {
        this.showTotales = false
        this.$swal({
          type: "success",
          icon: "success",
          title: "Exitoso !!",
          text: "Pedido Agregado Correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
        this.item = val;
        this.header = { importe: val.subtotal, impuesto: val.impuesto, total: val.total }
        this.table.body.data = val.detalles.map(art => {
          return {
            articulo: art.articulo,
            cantidad: art.cantidad,
            subtotal: art.subtotal,
            impuesto: art.impuesto,
            importe: art.total
          }
        })
        this.last_movement = val.last_movement;
        this.showTotales = true
        const action = val.last_movement.actions.find(action => action.accion === 'toPending')
        if (action) this.successAction({ action: action.accion, body: action })

      } else if (this.events.eventPending) {
        this.$swal({
          type: "success",
          icon: "success",
          title: "Exitoso !!",
          text: "Pago Realizodo Correctamente",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          this.$swal({
            title: "Desea imprimir el ticket?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Imprimir",
            cancelButtonText: "Cerrar",
          }).then((result) => {
            if (result.isConfirmed) {
              const route = this.get_urls['GET/venta/ventas/recibos/pdf/{folio}'];
              this.GETObjectPdftService({url: route, params: val.folio, replace: '{folio}' })
              this.$swal.fire({
                icon: "success",
                title: "Imprimiendo",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          });
        });
        this.clearStates();
      } else if (this.events.eventCancel) {
        this.$swal({
          type: "success",
          icon: "success",
          title: "Exitoso !!",
          text: "Venta Cancelada Correctamente",
          timer: 1500,
          showConfirmButton: false,
        });
        this.clearStates();
      }
    },
    get_objectDelete(value) {
      if (value.id)
        this.table.body.data = this.table.body.data.filter( (item) => item.id !== parseInt(value.id) )
      this.totalCalculate()
      this.table.header.loading = false
    },
    get_objectPdf(val) {
      this.objectMain = val
      this.paramsDialog = {
        form: this.get_ShowPdfticketDialog,
        message: 'Registro Agregado Correctamente',
        setmodel: true,
        returnObject: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'view-pdf', with: 850, title: 'Ticket Venta', body:{ isbutton: true } })
    },
  },
};
</script>
<style></style>
