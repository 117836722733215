<template>
    <v-container fluid>
        <v-layout justify-center>
            <v-flex xs12 sm10 md10>
              <DataTableMain :datatable="table" :on-success="successAction" :itemsAction="tableAction"/>
            </v-flex>
        </v-layout>
        <MainFormDialog v-if="get_dialogMain.dialog" :model="object" :params="paramsDialog"/>
</v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DataTableMain from '@/components/tables/DataTableMain'
import MainFormDialog from '@/components/dialogs/MainFormDialog'
import { Permission } from '@/mixins/permissionMain'
export default {
  mixins: [Permission],
  data: () => ({
    table: {
      header: {
        options: [
          { title: 'Refrescar', icon: 'mdi-cached', color: 'primary', action: 'refreshData', code: 'libre', hidden: '' },
          { title: 'Nuevo', icon: 'mdi-plus', color: 'success', action: 'newData', code: 'accesos-permisos.show',}
        ],
        titles: [
          { text: 'Nombre', value: 'nombre', class: 'black--text' },
          { text: 'Slug', value: 'slug', class: 'black--text' },
          { text: 'Descripcion', value: 'descripcion', class: 'black--text' },
          { text: 'Activo', value: 'Activo', class: 'black--text' },
          { text: '', value: 'ActionsGral', class: 'black--text' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: [
          { title: 'Editar', icon: 'mdi-pencil', color: 'warning', action: 'updateData', code: 'accesos-permisos.show' }
        ]
      },
      footer: {
        paging: true
      }
    },
    object: {},
    paramsDialog: {}
  }),
  components: {
    DataTableMain,
    MainFormDialog
  },
  mounted () {
    this.ExecuteUrl('POST/empresa/accesos/permisos/show')
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    ...mapActions(['GETUrlServices', 'POSTListObjectsService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'CLEAR_LIST_DATA']),
    /* ========== ========== ========== ========== ========== ========== ========== ========== */

    successAction (item) {
      this[item.action](item)
    },
    refreshData (item) {
      this.table.header.loading = true
      this.ExecuteUrl('POST/empresa/accesos/permisos/show')
    },
    newData (item) {
      this.object = {}
      this.paramsDialog.form = this.get_PermisosForm
      this.paramsDialog.url = this.get_urls['POST/empresa/accesos/permisos/store']
      this.paramsDialog.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'create', with: 850, title: 'Nuevo Permiso' })
    },
    updateData ({ item }) {
      this.object = item
      this.paramsDialog.form = this.get_PermisosForm
      this.paramsDialog.url = this.get_urls['POST/empresa/accesos/permisos/store']
      this.paramsDialog.message = 'Registro Agregado Correctamente'
      this.paramsDialog.setmodel = true
      this.SHOW_DIALOG_MAIN({ type: 'update', with: 850, title: 'Editar Permiso' })
    },
    tableAction (item) {
      this[item.action](item)
    },
    ExecuteUrl (objet) {
      if (this.get_urls != null) {
        const router = this.get_urls[objet]
        this.POSTListObjectsService({ url: router, data: {} })
      } else {
        this.GETUrlServices()
      }
    }
  },
  computed: {
    ...mapGetters(['get_dialogMain', 'get_urls', 'get_objects', 'get_PermisosForm'])
  },
  watch: {
    get_urls () {
      this.ExecuteUrl('POST/empresa/accesos/permisos/show')
    },
    get_objects (rows) {
      if (rows) {
        this.table.header.loading = false
        this.table.body.data = rows
      }
    }
  },
  beforeDestroy () {
    this.CLEAR_LIST_DATA()
  }

}
</script>

<style>

</style>
