import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VListItemActionText } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{attrs:{"label":_vm.itemAction.name,"item-text":_vm.itemText,"items":_vm.objects,"item-value":(_vm.itemAction.value) ? _vm.itemAction.value : 'id',"search-input":_vm.findObject,"no-data-text":"Sin Resultados","disabled":_vm.itemAction.readonly,"append-icon":(_vm.loading) ? 'mdi-refresh mdi-spin' : '',"clearable":"","outlined":"","dense":""},on:{"update:searchInput":function($event){_vm.findObject=$event},"update:search-input":function($event){_vm.findObject=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VListItemActionText,{attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-bold subtitle-2"},[_vm._v(_vm._s(_vm.itemText(item)))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemActionText,{attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-bold font-weight-black"},[_vm._v(_vm._s(_vm.itemText(item)))])])]}}]),model:{value:(_vm.object),callback:function ($$v) {_vm.object=$$v},expression:"object"}})}
var staticRenderFns = []

export { render, staticRenderFns }