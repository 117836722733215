import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.model.id)?_c(VCard,{staticClass:"elevation-2",attrs:{"tile":""}},[_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"9"}},[_c('p',{staticClass:"text-md-h4 text-sm-h5 text-center"},[_vm._v("Permisos del Rol")])]),_c(VCol,{attrs:{"cols":"3"}},[_c(VLayout,{attrs:{"justify-end":""}},[(_vm.showMainPermiss('accesos.roles.set-permiso'))?_c(VBtn,{attrs:{"color":"green darken-2","dark":""},on:{"click":_vm.sendPermission}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-check-outline")]),_vm._v(" Guardar Permisos ")],1):_vm._e()],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"4","md":"4"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardText,[(_vm.permisosMain.length > 0)?_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTreeview,{attrs:{"selection-type":"independent","items":_vm.permisosMain,"return-object":"","selectable":_vm.isPermiss},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1)],1):_vm._e()],1)],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"8","md":"8"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[(_vm.selection.length > 0)?_c(VCol,{attrs:{"cols":"12"}},[_c('DataTableMain',{attrs:{"datatable":_vm.table,"on-success":_vm.successAction,"items-action":_vm.tableAction}})],1):_vm._e()],1)],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }