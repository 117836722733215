import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{staticClass:"blue darken-2",attrs:{"id":"login"}},[_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"xs12":"","sm10":"","md6":"","lg5":""}},[_c(VCard,{staticClass:"elevation-4 pa-2",attrs:{"tile":""}},[_c(VCardText,[_c('div',{staticClass:"layout column align-center pa-1 ma-3"},[(_vm.logo)?_c('img',{attrs:{"src":_vm.logo,"contain":"","alt":"Vue Material Admin","width":"90%"}}):_vm._e()]),_c(VForm,{ref:"form"},[_c(VTextField,{attrs:{"label":"Usuario","rules":_vm.fieldRequired,"required":"","outlined":"","dense":""},model:{value:(_vm.login.usuario),callback:function ($$v) {_vm.$set(_vm.login, "usuario", $$v)},expression:"login.usuario"}}),_c(VTextField,{attrs:{"label":"Contraseña","append-icon":_vm.showeye ? 'visibility' : 'visibility_off',"append-icon-cb":function () { return (_vm.value = !_vm.value); },"rules":_vm.fieldRequired,"type":_vm.showeye ? 'text' : 'password',"required":"","outlined":"","dense":""},on:{"click:append":function($event){_vm.showeye = !_vm.showeye},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.loginAccess.apply(null, arguments)}},model:{value:(_vm.login.password),callback:function ($$v) {_vm.$set(_vm.login, "password", $$v)},expression:"login.password"}})],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"disabled":!_vm.valid,"color":"success","block":""},on:{"click":_vm.loginAccess}},[_vm._v(" Login ")])],1),_c(VProgressLinear,{attrs:{"active":_vm.loading,"indeterminate":true,"color":"green darken-3","height":"8","absolute":"","bottom":""}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }