import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from './views/LoginView.vue'
import InicioView from './views/Inicio.view.vue'
import DashboardView from './views/Dashboard.view'

import VentaView from './views/ventas/Venta.view.vue'
import VentaMostradorView from './views/ventas/VentaMostrador.view.vue'
import VentasCajaView from './views/ventas/VentasCaja.view.vue'
import MyCajaVentasView from './views/ventas/MyCajaVentas.view.vue'

import ComprasView from './views/compras/Solicitudes.view.vue'

import ArticulosView from './views/inventarios/Articulos.view.vue'
import AlmacenView from './views/inventarios/Almacen.view.vue'
import InvetariosGralView from './views/inventarios/InvetariosGral.view.vue'

import ProductosView from './views/catalogos/Productos.View.vue'
import CaracteristicasView from './views/catalogos/Caracteristicas.view.vue'
import ClientesView from './views/catalogos/Clientes.view.vue'
import FamiliasView from './views/catalogos/Familias.view.vue'
import TipoPagosView from './views/catalogos/TipoPagos.view.vue'
import TiposPreciosView from './views/catalogos/TiposPrecios.view.vue'
import TiposImpuestosView from './views/catalogos/TiposImpuestos.view.vue'
import TiposDatosView from './views/catalogos/TiposDatos.view.vue'
import MarcasView from './views/catalogos/Marcas.view.vue'
import ProveedoresView from './views/catalogos/Proveedores.view.vue'
import UnidadMedidaView from './views/catalogos/UnidadMedida.view.vue'
import AlmacenesView from './views/catalogos/Almacenes.view.vue'
import SucursalesView from './views/catalogos/Sucursales.view.vue'

import FormCaracteristicaView from './views/configuraciones/FormCaracteristicas.view.vue'
import ImportadorGeneralView from './views/configuraciones/ImportadorGeneral.view.vue'
import EmpleadosView from './views/configuraciones/Empleados.view.vue'
import UsersView from './views/configuraciones/accesos/Users.view.vue'
import RolesView from './views/configuraciones/accesos/Roles.view.vue'
import PermisosView from './views/configuraciones/accesos/Permisos.view.vue'
import PanelInvetarioView from './views/configuraciones/procesos/PanelInvetario.view'
import VariablesGlobalesView from './views/configuraciones/procesos/VariablesGlobales.view.vue'

import ReporteUtilidadView from './views/finanzas/reportes/ReporteUtilidad.view.vue'

import axios from 'axios';
import { Token } from '@/store/modules/config';


Vue.use(VueRouter)
const beforeEnter = (to, from, next) => {
  // console.log('next', to)
  // console.log('from', from)
  if (Token() != null) {
    return next()
  } else {
    return next({ name: 'initt' })
  }
  // console.log('Token', Token())
  // console.log('to', to)
  // console.log('from', from)
  // console.log('next', next)
  // next({ name: 'login' });
  // return next()
}
const routes = [
  {
    path: '/',
    name: 'initt',
    component: LoginView,
    meta: {
      breadcrumb: [
        { name: 'Dashboard' },
      ],
    },
  },

  {
    path: '/Inicio',
    name: 'inicio',
    meta: {
      allowAnonymous: true,
      name: 'INICIO',
      breadcrumb: [
        { name: 'Home' },
      ],
    },
    component: InicioView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Dashboard',
    name: 'DashboardView',
    meta: {
      allowAnonymous: true,
      name: 'Dashboard',
      breadcrumb: [
        { name: 'Dashboard' },
      ],
    },
    component: DashboardView,
    beforeEnter: beforeEnter
  },

  /* Catalogos */
  {
    path: '/Catalogos/Productos',
    name: 'ProductosView',
    meta: {
      allowAnonymous: true,
      name: 'Productos',
      breadcrumb: [
        { name: 'Catalogo' },
        { name: 'Productos' },
      ],
    },
    component: ProductosView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Catalogos/Caracteristicas',
    name: 'CaracteristicasView',
    meta: {
      allowAnonymous: true,
      name: 'Caracteristicas',
      breadcrumb: [
        { name: 'Catalogo' },
        { name: 'Caracteristicas' },
      ],
    },
    component: CaracteristicasView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Catalogos/Clientes',
    name: 'ClientesView',
    meta: {
      allowAnonymous: true,
      name: 'Clientes',
      breadcrumb: [
        { name: 'Catalogo' },
        { name: 'Clientes' },
      ],
    },
    component: ClientesView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Catalogos/Marcas',
    name: 'MarcasView',
    meta: {
      allowAnonymous: true,
      name: 'Marcas y Modelos',
      breadcrumb: [
        { name: 'Catalogo' },
        { name: 'Marcas' },
      ],
    },
    component: MarcasView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Catalogos/TipoDatos',
    name: 'TiposDatosView',
    meta: {
      allowAnonymous: true,
      name: 'Tipos de Datos',
      breadcrumb: [
        { name: 'Catalogo' },
        { name: 'Tipo Datos' },
      ],
    },
    component: TiposDatosView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Catalogos/TiposInpuestos',
    name: 'TiposInpuestosView',
    meta: {
      allowAnonymous: true,
      name: 'Tipos de Impuestos ',
      breadcrumb: [
        { name: 'Catalogo' },
        { name: 'Tipo Impuestos' },
      ],
    },
    component: TiposImpuestosView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Catalogos/Tipopagos',
    name: 'TiposPagosView',
    meta: {
      allowAnonymous: true,
      name: 'Tipos de Forma de Pago',
      breadcrumb: [
        { name: 'Catalogo' },
        { name: 'Tipo Pagos' },
      ],
    },
    component: TipoPagosView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Catalogos/Tipoprecios',
    name: 'TiposPreciosView',
    meta: {
      allowAnonymous: true,
      name: 'Tipos de Precios',
      breadcrumb: [
        { name: 'Catalogo' },
        { name: 'Tipo Precios' },
      ],
    },
    component: TiposPreciosView,
    beforeEnter: beforeEnter
  },  
  {
    path: '/Catalogos/UnidadMedidas',
    name: 'UnidadMedidasView',
    meta: {
      allowAnonymous: true,
      name: 'Tipos de Unidades de Medidas',
      breadcrumb: [
        { name: 'Catalogo' },
        { name: 'Tipo de Unidad de Medida' },
      ],
    },
    component: UnidadMedidaView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Catalogos/Almacenes',
    name: 'AlmacenesView',
    meta: {
      allowAnonymous: true,
      name: 'Tipos de Almacenes',
      breadcrumb: [
        { name: 'Catalogo' },
        { name: 'Tipos de Almacenes' },
      ],
    },
    component: AlmacenesView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Catalogos/Sucursales',
    name: 'SucursalesView',
    meta: {
      allowAnonymous: true,
      name: 'Tipos de Sucursales',
      breadcrumb: [
        { name: 'Catalogo' },
        { name: 'Tipos de Sucursales' },
      ],
    },
    component: SucursalesView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Catalogos/Familias',
    name: 'FamiliasView',
    meta: {
      allowAnonymous: true,
      name: 'Tipos de Familias',
      breadcrumb: [
        { name: 'Catalogo' },
        { name: 'Tipo de Familias' },
      ],
    },
    component: FamiliasView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Catalogos/Proveedores',
    name: 'ProveedoresView',
    meta: {
      allowAnonymous: true,
      name: 'Proveedores',
      breadcrumb: [
        { name: 'Catalogo' },
        { name: 'Proveedores' },
      ],
    },
    component: ProveedoresView,
    beforeEnter: beforeEnter
  },

  /* Ventas */
  {
    path: '/Ventas',
    name: 'VentaView',
    meta: {
      allowAnonymous: true,
      name: 'Venta',
      breadcrumb: [
        { name: 'Venta' },
      ],
    },
    component: VentaView,
    beforeEnter: beforeEnter
  },
  {
    path: '/VentaMostrador',
    name: 'VentaMostradorView',
    meta: {
      allowAnonymous: true,
      name: 'Venta Mostrador',
      breadcrumb: [
        { name: 'Mostrador' },
      ],
    },
    component: VentaMostradorView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Ventas/Caja',
    name: 'VentasCajaView',
    meta: {
      allowAnonymous: true,
      name: 'Caja',
      breadcrumb: [{ name: 'Ventas' }, { name: 'Caja' }],
    },
    component: VentasCajaView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Ventas/MyCaja',
    name: 'MyCajaVentasView',
    meta: {
      allowAnonymous: true,
      name: 'Caja',
      breadcrumb: [{ name: 'Ventas' }, { name: 'Caja' }],
    },
    component: MyCajaVentasView,
    beforeEnter: beforeEnter
  },

  /* Compras */
  {
    path: '/Compras/Solicitudes',
    name: 'CompSolicitudesView',
    meta: {
      allowAnonymous: true,
      name: 'Compras - Solicitudes',
      breadcrumb: [{ name: 'Compras' }, { name: 'Solicitudes' }],
    },
    component: ComprasView,
    beforeEnter: beforeEnter
  },
  /* Inventarios */
  {
    path: '/Inventarios/Articulos',
    name: 'InvArticulosView',
    meta: {
      allowAnonymous: true,
      name: 'Articulos',
      breadcrumb: [{ name: 'Inventarios' }, { name: 'Articulos' }],
    },
    component: ArticulosView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Inventarios/Almacen',
    name: 'InvAlmacenView',
    meta: {
      allowAnonymous: true,
      name: 'Almacen de Articulos',
      breadcrumb: [{ name: 'Inventarios' }, { name: 'Almacen' }],
    },
    component: AlmacenView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Inventarios/Almacen/AjustesInventarios',
    name: 'InvInvetariosGralView',
    meta: {
      allowAnonymous: true,
      name: 'Ajustes de Inventarios de Articulos',
      breadcrumb: [{ name: 'Inventarios' }, { name: 'Ajustes de Inventarios' }],
    },
    component: InvetariosGralView,
    beforeEnter: beforeEnter
  },

  /* Finanzas */
  {
    path: '/Finanzas/Reportes/Utilidad',
    name: 'FinanzaReportsUtilidadView',
    meta: {
      allowAnonymous: true,
      name: 'Reporte de Utilidades',
      breadcrumb: [{ name: 'Finanza' }, { name: 'Reporte Utilidad' }],
    },
    component: ReporteUtilidadView,
    beforeEnter: beforeEnter
  },

  /* Configuuraciones */
  {
    path: '/Configuraciones/FormFamiliaCaracterristicas',
    name: 'FormCaracteristicaView',
    meta: {
      allowAnonymous: true,
      name: 'Formulario de Familia-Caracteristicas',
      breadcrumb: [{ name: 'Configuraciones' }, { name: 'Formulario de Familia y Caracteristicas' }],
    },
    component: FormCaracteristicaView,
    beforeEnter: beforeEnter
  },

  {
    path: '/Configuraciones/Acceso/Usuarios',
    name: 'ConfigAuthUsersView',
    meta: {
      allowAnonymous: true,
      name: 'Usuarios',
      breadcrumb: [{ name: 'Configuraciones' }, { name: 'Accesos' }, { name: 'Usuarios' }]
    },
    component: UsersView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Configuraciones/Acceso/Roles',
    name: 'ConfigAuthRolsView',
    meta: {
      allowAnonymous: true,
      name: 'Roles',
      breadcrumb: [{ name: 'Configuraciones' }, { name: 'Accesos' }, { name: 'Roles' }]
    },
    component: RolesView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Configuraciones/Acceso/Permisos',
    name: 'ConfigAuthPermissView',
    meta: {
      allowAnonymous: true,
      name: 'Permisos del Sitema',
      breadcrumb: [{ name: 'Configuraciones' }, { name: 'Accesos' }, { name: 'Permisos' }]
    },
    component: PermisosView,
    beforeEnter: beforeEnter
  },

  {
    path: '/Configuraciones/RutinasProcesos/Panel',
    name: 'ConfigRutProcConteoInventView',
    meta: {
      allowAnonymous: true,
      name: 'Panel de Inventarios',
      breadcrumb: [{ name: 'Configuraciones' }, { name: 'Rutinas y Procesos' }, { name: 'Panel de Inventarios' }]
    },
    component: PanelInvetarioView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Configuraciones/VariablesGlobales',
    name: 'ConfigVariablesGlobalesView',
    meta: {
      allowAnonymous: true,
      name: 'Configuraciones Globales del Sistema',
      breadcrumb: [{ name: 'Configuraciones' }, { name: 'Rutinas y Procesos' }, { name: 'Configuraciones Globales' }]
    },
    component: VariablesGlobalesView,
    beforeEnter: beforeEnter
  },

  {
    path: '/Configuraciones/Empleados',
    name: 'ConfigEmpleadosView',
    meta: {
      allowAnonymous: true,
      name: 'Empleados',
      breadcrumb: [{ name: 'Configuraciones' }, { name: 'Empleados' }]
    },
    component: EmpleadosView,
    beforeEnter: beforeEnter
  },
  {
    path: '/Configuraciones/ImportadorGeneral',
    name: 'ConfigImportadorGeneralView',
    meta: {
      allowAnonymous: true,
      name: 'Permisos del Sitema',
      breadcrumb: [{ name: 'Configuraciones' }, { name: 'Importador General' }]
    },
    component: ImportadorGeneralView,
    beforeEnter: beforeEnter
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
