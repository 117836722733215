import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto",attrs:{"elevation":"0","title":""}},[_c(VCardTitle,{staticClass:"pa-1 ma-1 justify-center"},[_c('h2',[_vm._v(_vm._s(_vm.itemAction.name))])]),_c(VVirtualScroll,{attrs:{"items":_vm.listMain,"item-height":90,"min-height":"350"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c(VListItem,{attrs:{"dense":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.itemText(item))+" ")])],1),_c(VListItemAction,[(_vm.validarRecepcion(item) > 0)?_c(VTextField,{attrs:{"label":"Recepcionar","type":"number","min":"0","outlined":"","dense":""},on:{"keyup":function($event){return _vm.confirmCheck(item)}},model:{value:(item.recepcion_faltante),callback:function ($$v) {_vm.$set(item, "recepcion_faltante", _vm._n($$v))},expression:"item.recepcion_faltante"}}):_c('label',[_vm._v(" "+_vm._s(item.cantidad_recepcion)+" ")])],1)],1),_c(VDivider)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }