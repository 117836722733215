<template>
  <v-container>
    <v-row dense class="py-3">
      <v-col v-for="(action,index) in directos" :key="index" class="px-4" cols="3" :class="action.hidden">
        <v-card tile @click="menu(action.action)">
          <v-img src="@/assets/logo.png"
            class="white--text align-end"
            gradient="to bottom, rgba(2,40,68,.83), rgba(9,65,107,.3)"
            max-height="280px">
            <v-card-text class="align-center text-center">
              <div class="mb-4">
                <v-btn fab :color="action.color" elevation="0" x-large dark>
                  <v-icon x-large>{{action.icon}}</v-icon>
                </v-btn>
              </div>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1 title text-uppercase white--text font-weight-bold">{{action.title}}</v-list-item-title>
                  <v-list-item-subtitle class="white--text">{{action.message}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import { mapActions, mapGetters, mapMutations } from 'vuex';
import router from '@/router'
import { Permission } from '@/mixins/permissionMain'
import { UserSession } from '@/store/modules/config';
export default {
  mixins: [Permission],
  name: 'DashboardView',
  components: {},
  data: () => ({
    urls: {},
    user: {},
    directos: [
      {
        id: 'compra.list-params',
        icon: 'mdi-account-group',
        color: 'lime darken-3',
        action: 'CompSolicitudesView',
        title: 'Compras',
        message: 'Solicitudes de compras para el inventario o servicios',
        hidden: 'hiddenItem'
      },
      {
        id: 'catalogo.productos.show-params',
        icon: 'mdi-account-group',
        color: 'lime darken-3',
        action: 'InvArticulosView',
        title: 'Inventario',
        message: 'Inventario de Articulos y disponibles',
        hidden: 'hiddenItem'
      },
      {
        id: 'venta.show-params',
        icon: 'mdi-account-group',
        color: 'lime darken-3',
        action: 'VentaView',
        title: 'Ventas',
        message: 'listado de ventas por dia',
        hidden: 'hiddenItem'
      },
      {
        id: 'ventas.mostrador.show',
        icon: 'mdi-account-group',
        color: 'lime darken-3',
        action: 'VentaMostradorView',
        title: 'Venta Mostrador',
        message: 'modulo de ventas para mostrador',
        hidden: 'hiddenItem'
      }
    ]
  }),
  mounted() {
    this.user = UserSession()
    this.PermisosMenu(JSON.parse(localStorage.getItem('permisos')), this.directos)
  },
  methods: {
    ...mapActions(['GETUrlServices']),
    ...mapMutations([]),
    menu (item) {
      router.push({ name: item })
    }
  },
  computed: {
    ...mapGetters(['get_urls']),
  },
  watch: {
    get_urls(val) {
      this.urls = val;
    },
  },
};
</script>

<style scoped>
.hiddenItem {
  display: none;
}
</style>
