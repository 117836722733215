<template>
  <v-app id="inspire">
    <template v-if="!$route.meta.allowAnonymous">
      <v-main>
        <router-view />
      </v-main>
    </template>
    <template v-else>
      <NavigationDrawer :toggle="drawer" />
      <ToolbarDrawer
        :name="$route.meta.name"
        @toggleNavigationBar="drawer = !drawer"
      />
      <v-main>
        <v-progress-linear :active="loading" :indeterminate="loading" height="10" striped color="green darken-3">
        </v-progress-linear>
        <router-view />
      </v-main>
      <v-footer color="grey darken-1" app>
        <span class="white--text">&copy; {{ year() }}</span>
      </v-footer>
    </template>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  components: {},
  data: () => ({
    drawer: false,
    loading: false
  }),
  methods: {
    year () {
      const year = new Date().toISOString().substring(0, 4)
      return year
    }
  },
  computed: {
    ...mapGetters(['get_showLoadingDialog'])
  },
  watch : {
    get_showLoadingDialog (show) {
      this.loading = show
    }
  }
}
</script>
<style scoped>
#inspire {
  background-color: #EEEEEE;
}
</style>
