<template>
    <v-app-bar app class="grey darken-1" dark>
      <v-app-bar-nav-icon @click="toggleNavigationBar" />
      <v-toolbar-title>
        <span class="font-weight-bold font-weight-black title text-uppercase">{{ name }}</span>
      </v-toolbar-title>
      <div class="flex-grow-1" />
      <!-- <breadcrumbs /> -->
    </v-app-bar>

</template>

<script>
export default {
  name: 'ToolbarView',
  props: ['name'],
  data: () => ({}),
  methods: {
    toggleNavigationBar () {
      const vm = this
      vm.$emit('toggleNavigationBar')
    }
  }
}
</script>

<style>

</style>
