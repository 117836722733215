<template>
  <DataTableMain
    :datatable="table"
    :on-success="successAction"
    :items-action="tableAction"
  />
</template>

<script>
import DataTableMain from "@/components/tables/DataTableMain.vue";
import axios from 'axios';
import { HeaderGral } from '@/store/modules/config';

export default {
  props: ["itemAction", "onSuccess"],
  data: () => ({
    table: {
      modelMain: [],
      showSelect:true,
      singleSelect:true,
      class_items: false,
      header: {
        options: [
          { title: 'Refrescar', icon: 'mdi-cached', color: 'primary', action: 'refreshData', code: 'libre', hidden: '' }
        ],
        showSearch:true,
        titles: [],
        loading: false,
      },
      body: {
        data: [],
        actions: [],
      },
      footer: {
        paging: true,
      },
    }
  }),
  created() {
    this.table.class_items = this.itemAction.color_row
    this.table.header.titles = this.itemAction.titles
    if (this.itemAction.url) {
      this.getDinamicData(this.itemAction.url)
    } else {
      this.table.body.data = this.itemAction.items
    }
    // this.setModel()
  },
  methods: {
    getDinamicData (url) {
      this.table.header.loading = true
      const header = HeaderGral();
      axios.post(url, this.itemAction.data, header).then((response) => {
        this.table.body.data = response.data
      }).catch((error) => {
        if (error == null) {
          console.log('Error');
        }
      }).finally(()=>{
        this.table.header.loading = false
      })
    },
    refreshData(item) {
      if (this.itemAction.url) {
        this.getDinamicData(this.itemAction.url)
      }
    },
    successAction(item) {
      this[item.action](item);
    },

    tableAction(item) {
      return this[item.action](item);
    },
    rowColor ({ item }) {
      if ( this.itemAction.nameid === 'article'){
        return (item.inventarios.picking <= 0) ? 'red-darken-1' : 'white'
      }
      return 'white'
    },
  },
  watch: {
    object(val){
    if (val) this.onSuccess({id: this.itemAction.nameid, data: val})
   }
  },
  computed:{
    object(){
        if(this.table.modelMain.length>0)
            return this.table.modelMain[0]
        return null
    }
  },
  components: { DataTableMain },
};
</script>

<style></style>
