import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.model.id || _vm.model.cve_empleado)?_c(VCard,{staticClass:"elevation-2"},[_c(VCardTitle,{staticClass:"text-h5 text-sm-h4 text-uppercase"},[_c('label',{staticClass:"font-weight-bold text--primary ma-auto text-center"},[_vm._v("Historial de Movimientos")])]),_c(VCardText,{staticClass:"scroll-box"},[_c(VTimeline,{attrs:{"align-top":"","dense":""}},_vm._l((_vm.model.movements),function(movement,index){return _c(VTimelineItem,{key:index,attrs:{"color":movement.estatus.color,"small":""}},[_c('div',[_c('div',{staticClass:"font-weight-normal"},[_c(VChip,{attrs:{"color":movement.estatus.color,"label":"","outlined":""}},[_vm._v(_vm._s(movement.estatus.estatus))]),_vm._v(" @ "),_c('label',{staticClass:"font-weight-bold subtitle-2 text--primary"},[_vm._v(" "+_vm._s(new Date(movement.created_at).toISOString().substr(0, 10))+" "+_vm._s(new Date(movement.created_at).toLocaleTimeString('it-IT'))+" ")])],1),(movement.estatus_id === 23)?_c(VRow,{attrs:{"dense":""}},_vm._l((movement.estatus.formulario.fields),function(field,i){return _c(VCol,{key:i,attrs:{"cols":field.cols,"offset":field.offset,"hidden":field.hidden}},[_c('div',{staticClass:"font-weight-bold black--text"},[_vm._v(_vm._s(field.name))]),_c('div',[_vm._v(_vm._s(movement.detalle.descripcion[field.nameid]))])])}),1):(movement.detalle)?_c('div',{staticClass:"pl-1"},_vm._l((movement.estatus.formulario.fields),function(field,index){return _c('p',{key:index,staticClass:"font-italic ma-0"},[_c('span',{staticClass:"font-weight-bold font-weight-black"},[_vm._v(" "+_vm._s(field.name)+":")]),(_vm.containsString('_id', field.nameid))?_c('span',[_vm._v(" "+_vm._s(_vm.setNameObject(movement.detalle.descripcion, field.nameid))+" ")]):_c('span',[_vm._v(" "+_vm._s(movement.detalle.descripcion[field.nameid])+" ")])])}),0):_vm._e()],1)])}),1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }