<template>
 <span v-show="showtext" class="font-weight-bold black--text">
    {{ TextMain }}
 </span>
</template>
<script> 
import axios from 'axios';
import { HeaderGral } from '@/store/modules/config';
export default {
  props: ['itemAction', 'setterModel'],
  data: () => ({
    showtext: false,
    TextMain: '',
    paramsMain: '',
    items: [],
  }),
  methods: {
    ItemAction (item) {
      this.OnSuccess(item)
    }
  },
  mounted () {
    if (this.itemAction.url) {
      this.getDinamicData(this.itemAction.url);
    }
  },
  methods: {
    getDinamicData(url) {
        const params = this.SetParams()
      if (url !== '') {
        const header = HeaderGral();
        axios.get(`${url}${params}`, header).then((response) => {
          if (this.itemAction.type == 'objet') {
            this.TextMain = response.data[this.itemAction.nameid]
          } else if (this.itemAction.type == 'list') {
            this.TextMain = ''
            response.data.map(resp =>{
              this.TextMain += (this.TextMain === '') ? resp[this.itemAction.nameid] : `, ${resp[this.itemAction.nameid]}`
            })
          }
          this.showtext = true
        }).catch((error) => {
          if (error == null) {
            console.log('Error')
          }
        });
      } else {
        this.items.push(...this.itemAction.items);
      }
    },
    SetParams () {
      if (this.itemAction.typeField === 'Default') { // variable para las sucursales del usuario
        const configuracion = JSON.parse(this.setterModel.pivot.configuracion)
        const p = this.itemAction.params.replace(this.itemAction.replace,[configuracion.default])
        return p
      } else if (this.itemAction.typeField === 'Almacenes') { // variable para las sucursales del usuario
        const configuracion = JSON.parse(this.setterModel.pivot.configuracion)
        let ids= ''
        configuracion.almacenes.map(a => {
          ids += (ids === '') ? a : `,${a}`
        })
        const p = this.itemAction.params.replace(this.itemAction.replace,[ids])
        return p
      }

    }
  }
}
</script>

<style>
</style>
