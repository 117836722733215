<template>
    <v-container fluid>
      <v-row dense>
        <v-col cols="12">
          <DataTableMain :datatable="table" :on-success="successAction" :itemsAction="tableAction"/>
        </v-col>
        <v-col cols="12">
          <ViewDetailsMain class="my-2" :object="objectDetail" type="UsuarioDetails" :items-action="tableAction" />
        </v-col>
      </v-row>
        <MainFormDialog v-if="get_dialogMain.dialog" :model="object" :params="paramsDialog" :on-success="ResponseObject"/>
    </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DataTableMain from '@/components/tables/DataTableMain'
import MainFormDialog from '@/components/dialogs/MainFormDialog'
import ViewDetailsMain from '@/components/details/ViewDetailsMain.view'
import { Permission } from '@/mixins/permissionMain'
export default {
  mixins: [Permission],
  data: () => ({
    table: {
      modelMain: [],
      showSelect: true,
      singleSelect: true,
      header: {
        options: [
          { title: 'Refrescar', icon: 'mdi-cached', color: 'primary', action: 'refreshData', code: 'libre', hidden: '' },
          { title: 'Nuevo', icon: 'mdi-plus', color: 'success', action: 'newData', code: 'accesos-usuarios.store' }
        ],
        titles: [
          { text: 'Cve Empleado', value: 'empleado.cve_empleado', class: 'black--text' },
          { text: 'Usuario', value: 'usuario', class: 'black--text' },
          { text: 'Empleado', value: 'empleado.NombreCompleto', class: 'black--text' },
          { text: 'Email', value: 'email', class: 'black--text' },
          { text: 'Sucursal', value: 'empleado.sucursal.nombre', class: 'black--text' },
          { text: 'Activo', value: 'Activo', class: 'black--text' },
          // { text: 'Fecha Creacion', value: 'Created', class: 'black--text' },
          // { text: 'Fecha Actualizada', value: 'Updated', class: 'black--text' },
          { text: '', value: 'ActionsGral', class: 'black--text' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: [
          { title: 'Editar', icon: 'mdi-pencil', color: 'warning', action: 'updateData', code: 'accesos-usuarios.update' },
          { title: 'Cambiar Contraseña', icon: 'mdi-account-key', color: 'info', action: 'updatePasswordData', code: 'usuarios.change-password' }
        ]
      },
      footer: {
        paging: true
      }
    },
    object: {},
    paramsDialog: {}
  }),
  components: {
    DataTableMain,
    MainFormDialog,
    ViewDetailsMain
  },
  mounted () {
    this.ExecuteUrl('POST/empresa/accesos/usuarios/show')
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    ...mapActions(['GETUrlServices', 'POSTListObjectsService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'CLEAR_LIST_DATA']),
    /* ========== ========== ========== ========== ========== ========== ========== ========== */

    successAction (item) {
      this[item.action](item)
    },
    refreshData (item) {
      this.table.header.loading = true
      this.ExecuteUrl('POST/empresa/accesos/usuarios/show')
    },
    newData (item) {
      this.object = {}
      this.paramsDialog.form = this.get_UserForm
      this.paramsDialog.url = this.get_urls['POST/empresa/accesos/usuarios/store']
      this.paramsDialog.message = 'Registro Agregado Correctamente'
      this.SHOW_DIALOG_MAIN({ type: 'create', with: 850, title: 'Nuevo Usuario' })
    },
    newDataRoles ({ item }) {
      this.object = {
        id: item.id,
        usuario: item.usuario,
        NombreCompleto: item.empleado.NombreCompleto,
      }
      this.paramsDialog.form = this.get_AssignRoles
      this.paramsDialog.url = this.get_urls['POST/empresa/accesos/usuarios/assign-rol']
      this.paramsDialog.message = 'Registro Agregado Correctamente'
      this.paramsDialog.setmodel = true
      this.paramsDialog.returnObject = true
      this.SHOW_DIALOG_MAIN({ type: 'assignrol', with: 850, title: `Asignar Rol (${item.empleado.NombreCompleto})` })
    },
    newDataSucursal ({ item }) {
      this.object = {
        id: item.id,
        usuario: item.usuario,
        NombreCompleto: item.empleado.NombreCompleto,
      }
      this.paramsDialog.form = this.get_AssignSucursal
      this.paramsDialog.url = this.get_urls['POST/empresa/accesos/usuarios/assign-sucursal']
      this.paramsDialog.message = 'Registro Agregado Correctamente'
      this.paramsDialog.setmodel = true
      this.paramsDialog.returnObject = true
      this.SHOW_DIALOG_MAIN({ type: 'assignsucursal', with: 850, title: `Asignar Sucursal (${item.empleado.NombreCompleto})` })
    },
    daleteDataRol ({ item, model }) {
      this.object = { id: model.id, rol_id: item.id }
      this.paramsDialog.form = this.get_ShowMessageDialog
      this.paramsDialog.url = this.get_urls['POST/empresa/accesos/usuarios/remove-rol']
      this.paramsDialog.message = 'Registro Agregado Correctamente'
      this.paramsDialog.setmodel = true
      this.paramsDialog.returnObject = true
      this.SHOW_DIALOG_MAIN({ type: 'removerol', with: '30%', title: 'Eliminar Rol', body:{ name_button: 'Si, Eliminar', rename: true }  })
    },
    daleteDataSucursal ({ item, model }) {
      // console.log('model', model)
      // console.log('item', item)
      this.object = {
        id: model.id,
        sucursal_id: item.id
      }
      this.paramsDialog.form = this.get_ShowMessageSucursalDialog
      this.paramsDialog.url = this.get_urls['POST/empresa/accesos/usuarios/remove-sucursal']
      this.paramsDialog.message = 'Registro Agregado Correctamente'
      this.paramsDialog.setmodel = true
      this.paramsDialog.returnObject = true
      this.SHOW_DIALOG_MAIN({ type: 'removesucursal', with: '30%', title: 'Eliminar Sucursal', body:{ name_button: 'Si, Eliminar', rename: true }  })
      // console.log('model', model)
      // console.log('item', item)
    },
    updateData ({ item }) {
      this.object = item
      this.paramsDialog.form = this.get_UserUpdateForm
      this.paramsDialog.url = this.get_urls['POST/empresa/accesos/usuarios/update']
      this.paramsDialog.message = 'Registro Actualizado Correctamente'
      this.paramsDialog.setmodel = true
      this.SHOW_DIALOG_MAIN({ type: 'update', with: 850, title: `Editar Usuario ( ${item.usuario} )` })
    },
    updatePasswordData ({ item }) {
      this.object = { id: item.id }
      this.paramsDialog.form = this.get_PasswordUpdateForm
      this.paramsDialog.url = this.get_urls['POST/empresa/accesos/usuarios/change-password']
      this.paramsDialog.message = 'Registro Actualizado Correctamente'
      this.paramsDialog.setmodel = true
      this.SHOW_DIALOG_MAIN({ type: 'change-password', with: 850, title: `Cambiar Contraseña ( ${item.usuario} )` })
    },
    /* ========== ========== ========== ========== ========== */
    tableAction (item) {
      this[item.action](item)
    },
    toEditData (item) {
      this.user = item.item
      this.SHOW_DIALOG_MAIN({ type: 'update', with: 500, title: 'Editar Usuario' })
    },
    ExecuteUrl (objet) {
      if (this.get_urls != null) {
        const router = this.get_urls[objet]
        this.POSTListObjectsService({ url: router, data: {} })
      } else {
        this.GETUrlServices()
      }
    },
    ResponseObject ({ name, model }) {
      this.table.header.loading = true
      this.table.modelMain = []
      if (name === 'assignrol') {
        this.table.body.data.map(row => {
          if (row.id === model.id) {
            row.roles = model.roles
            setTimeout(() => { this.table.modelMain.push(row) }, 1)
          }
        })
      } else if (name === 'removerol') {
        this.table.body.data.map(row => {
          if (row.id === model.id) {
            row.roles = model.roles
            setTimeout(() => { this.table.modelMain.push(row) }, 1)
          }
        })
      } else if (name === 'assignsucursal') {
        this.table.body.data.map(row => {
          if (row.id === model.id) {
            row.sucursales = model.sucursales
            setTimeout(() => { this.table.modelMain.push(row) }, 1)
          }
        })
      } else if (name === 'removesucursal') {
        this.table.body.data.map(row => {
          if (row.id === model.id) {
            row.sucursales = model.sucursales
            setTimeout(() => { this.table.modelMain.push(row) }, 1)
          }
        })
      }
      this.table.header.loading = false
    }
  },
  computed: {
    ...mapGetters(['get_urls', 'get_objects', 'get_dialogMain', 'get_UserForm', 'get_AssignRoles', 'get_AssignSucursal',
      'get_ShowMessageDialog', 'get_ShowMessageSucursalDialog',
      'get_UserUpdateForm', 'get_PasswordUpdateForm']),
    objectDetail() {
      if (this.table.modelMain.length > 0) {
        return this.table.modelMain[0]
      }
      return {}
    }
  },
  watch: {
    get_urls () {
      this.ExecuteUrl('POST/empresa/accesos/usuarios/show')
    },
    get_objects (rows) {
      this.table.header.loading = true
      if (rows) {
        this.table.modelMain = []
        if (rows.length > 0) this.table.modelMain.push(rows[0])
        this.table.header.loading = false
        this.table.body.data = rows
      }
    }
  },
  beforeDestroy () {
    this.CLEAR_LIST_DATA();
  }

}
</script>

<style>

</style>
crear curd de roles
