<template>
  <v-card v-if="model.id" class="elevation-2" tile>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <DataTableMain :datatable="table" :on-success="successAction" :items-action="tableAction" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import DataTableMain from '@/components/tables/DataTableMain'
import { Permission } from '@/mixins/permissionMain'
export default {
  name: 'AjusteInventarioDetails',
  mixins: [Permission],
  components: {
    DataTableMain
  },
  props: ['model', 'onSuccess'],
  mounted() {
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  data: () => ({
    table: {
      header: {
        options: [
          { title: 'Reporte Articulos Diferentes', icon: 'mdi-clipboard-text', color: 'yellow darken-3', action: 'ExportdiferenteInventory', code: 'inventario.ajuste.list.detalle.reporte-excel' }
        ],
        titles: [
          { text: 'Folio', value: 'articulo.folio', class: 'black--text' },
          { text: 'Descripcion', value: 'articulo.caracteristicas.descripcion', class: 'black--text' },
          { text: 'Unidad', value: 'articulo.udm.nombre', class: 'black--text' },

          { text: 'Entrada Sistema / Importado', value: 'InventarioAjuste', class: 'black--text', align: 'center', checkin: true },
          { text: 'Bodega Sistema / Importado', value: 'InventarioAjuste', class: 'black--text', align: 'center', stock: true },
          { text: 'EnVenta Sistema / Importado', value: 'InventarioAjuste', class: 'black--text', align: 'center', picking: true },
          { text: 'Total Sistema / Importado', value: 'InventarioAjuste', class: 'black--text', align: 'center', total: true },

          { text: 'Facturado Sistema / Importado', value: 'InventarioAjuste', class: 'black--text', align: 'center', facturado: true },
          { text: 'No Facturado Sistema / Importado', value: 'InventarioAjuste', class: 'black--text', align: 'center', nofacturado: true },
          { text: '', value: 'ActionsGral', class: 'black--text', align: 'right' }
        ],
        loading: false,
        showSearch: true
      },
      body: {
        data: [],
        actions: [
          { title: 'Editar', icon: 'mdi-pencil', color: 'error', action: 'updateAjusteInventario', code: 'libre', hidden: '' }
        ]
      },
      footer: {
        paging: true
      }
    }
  }),
  methods: {
    successAction (item) {
      item.item = this.model
      this.onSuccess(item)
    },
    tableAction (item) {
      item.main = this.model
      this.onSuccess(item)
    }
  },
  watch: {
    model (row) {
      if (row.diferencias) {
        this.table.body.data = row.diferencias
      }
    }
  }
}
</script>

<style scoped>
.background-colum-facturado {
  color: #56859c;
  font-weight: bold;
}
.background-colum-total {
  color: #0c8934;
  font-weight: bold;
}
</style>
  