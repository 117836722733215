import { VForm } from 'vuetify/lib/components/VForm';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"formSelect"},[_c(VSelect,{attrs:{"items":_vm.items,"label":_vm.itemAction.name,"item-text":_vm.itemText,"item-value":(_vm.itemAction.itemvalue) ? _vm.itemAction.itemvalue: 'id',"readonly":_vm.itemAction.readonly,"clearable":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.itemText(item))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(item.padre_id == 0)?[_c(VListItemContent,[_c('span',{staticClass:"font-weight-bold subtitle-2"},[_vm._v(_vm._s(_vm.itemText(item)))])])]:[_c(VListItemContent,{staticClass:"pl-3",domProps:{"textContent":_vm._s(_vm.itemText(item))}})]]}}]),model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }