<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <DataTableMain :datatable="table" :on-success="successAction" :itemsAction="tableAction"/>
      </v-col>
      <v-col cols="12">
        <ViewDetailsMain class="my-2" :object="objectDetail" type="RolPermisosDetails" :items-action="tableAction" />
      </v-col>
    </v-row>
    <MainFormDialog v-if="get_dialogMain.dialog" :model="object" :params="paramsDialog"/>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import router from '@/router'
import DataTableMain from '@/components/tables/DataTableMain'
import MainFormDialog from '@/components/dialogs/MainFormDialog'
import ViewDetailsMain from '@/components/details/ViewDetailsMain.view'
import { Permission } from '@/mixins/permissionMain'
export default {
  mixins: [Permission],
  data: () => ({
    table: {
      modelMain: [],
      showSelect: true,
      singleSelect: true,
      header: {
        options: [
          { title: 'Refrescar', icon: 'mdi-cached', color: 'primary', action: 'refreshData', code: 'libre', hidden: '' },
          { title: 'Nuevo', icon: 'mdi-plus', color: 'success', action: 'newData', code: 'accesos.roles.store' }
        ],
        titles: [
          { text: 'Clave', value: 'slug', class: 'black--text' },
          { text: 'Nombre', value: 'nombre', class: 'black--text' },
          { text: 'Acceso Especial', value: 'AccessAll', class: 'black--text' },
          { text: 'Activo', value: 'Activo', class: 'black--text' },
          { text: '', value: 'ActionsGral', class: 'black--text' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: [
          { title: 'Editar', icon: 'mdi-pencil', color: 'warning', action: 'updateData', code: 'accesos.roles.store' }
        ]
      },
      footer: {
        paging: true
      }
    },
    object: {},
    paramsDialog: {}
  }),
  components: {
    DataTableMain,
    MainFormDialog,
    ViewDetailsMain
  },
  mounted () {
    this.ExecuteUrl('POST/empresa/accesos/roles/show')
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    ...mapActions(['GETUrlServices', 'POSTListObjectsService', 'POSTObjectsService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'CLEAR_LIST_DATA']),
    /* ========== ========== ========== ========== ========== ========== ========== ========== */
    successAction (item) {
      this[item.action](item)
    },
    refreshData (item) {
      this.table.header.loading = true
      this.ExecuteUrl('POST/empresa/accesos/roles/show')
    },
    newData (item) {
      this.object = { descripcion: { normal: '' } }
      this.paramsDialog.form = this.get_RoleForm
      this.paramsDialog.url = this.get_urls['POST/empresa/accesos/roles/store']
      this.paramsDialog.message = 'Registro Agregado Correctamente'
      this.paramsDialog.setmodel = true
      this.SHOW_DIALOG_MAIN({ type: 'create', with: 850, title: 'Nuevo Rol' })
    },
    updateData ({ item }) {
      this.object = item
      this.paramsDialog.form = this.get_RoleForm
      this.paramsDialog.url = this.get_urls['POST/empresa/accesos/roles/store']
      this.paramsDialog.message = 'Registro Agregado Correctamente'
      this.paramsDialog.setmodel = true
      this.SHOW_DIALOG_MAIN({ type: 'create', with: 850, title: 'Nuevo Rol' })
    },
    tableAction (item) {
      this[item.action](item)
    },

    permissionsAssign ({ item }) {
      const router = this.get_urls['POST/empresa/accesos/roles/set-permiso']
      // console.log('item', item)
      this.POSTObjectsService({ url: router, data: item })
    },
    ExecuteUrl (objet) {
      if (this.get_urls != null) {
        const router = this.get_urls[objet]
        this.POSTListObjectsService({ url: router, data: {} })
      } else {
        this.GETUrlServices()
      }
    },
  },
  computed: {
    ...mapGetters(['get_dialogMain', 'get_urls', 'get_object', 'get_objects', 'get_RoleForm']),
    objectDetail() {
      if (this.table.modelMain.length > 0) {
        return this.table.modelMain[0]
      }
      return {}
    }
  },
  watch: {
    get_urls () {
      this.ExecuteUrl('POST/empresa/accesos/roles/show')
    },
    get_objects (rows) {
      if (rows) {
        this.table.modelMain = []
        if (rows.length > 0) this.table.modelMain.push(rows[0])
        this.table.header.loading = false
        this.table.body.data = rows
      }
    },
    get_object (row) {
      if (row) {
        this.$swal({
          type: 'success',
          icon: 'success',
          title: 'Existoso!!',
          text: 'Permisos agregados correctamente',
          timer: 2000
        })
        let findobject = -1;
        this.table.body.data.map((el, i) => { if (el.id === data.id) findobject = i })
        if (findobject !== -1) Object.assign(this.table.body.data[findobject], data)
      }
    }
  },
  beforeDestroy () {
    this.CLEAR_LIST_DATA()
  }

}
</script>

<style>

</style>
