import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{attrs:{"tile":""}},[_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('SelectedDinamicField',{attrs:{"setter-model":_vm.sucursal_id,"item-action":_vm.SelectedSucursalField,"on-success":_vm.actionFieldData}})],1),(_vm.showAlmacen)?_c(VCol,{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('SelectedDinamicField',{attrs:{"setter-model":_vm.almacen_id,"item-action":_vm.SelectedAlmacenField,"on-success":_vm.actionFieldData}})],1):_vm._e()],1)],1)],1),_c(VTabs,{attrs:{"background-color":"primary","dark":""},model:{value:(_vm.tabMain),callback:function ($$v) {_vm.tabMain=$$v},expression:"tabMain"}},_vm._l((_vm.estatus),function(item,index){return _c(VTab,{key:index,on:{"click":function($event){return _vm.getStatusAlmacen(item.id)}}},[_vm._v(" "+_vm._s(item.nombre)+" ("+_vm._s(item.estatus)+") ")])}),1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[(_vm.showTable)?_c('DataTableMainServer',{attrs:{"datatable":_vm.table,"on-success":_vm.successAction,"itemsAction":_vm.tableAction,"Updatepagination":_vm.params,"on-pagination":_vm.pagePagination,"on-search":_vm.getSearchPagePagination}}):_vm._e()],1)],1),(_vm.get_dialogMain.dialog)?_c('MainFormDialog',{attrs:{"model":_vm.object,"params":_vm.paramsDialog,"on-success":_vm.ResponseForm}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }