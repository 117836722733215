import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"tile":""}},[_c(VCardText,[_c(VRow,{staticClass:"pb-3",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[(_vm.showMainPermiss('venta.clientes.create-update'))?_c(VBtn,{staticClass:"mx-2",attrs:{"color":"info","tile":"","dark":""},on:{"click":function($event){return _vm.successtableAction({ action: 'newClientData', item: {} })}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Agregar Cliente ")],1):_vm._e()],1),_c(VDivider,{staticClass:"pa-3 black--text",attrs:{"width":"5"}})],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","sm":"4","md":"3","lg":"3"}},[_c('SelectedDinamicField',{attrs:{"setter-model":_vm.sucursal_id,"item-action":_vm.SelectedSucursalField,"on-success":_vm.actionFieldData}})],1),_c(VCol,{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('DatePicker',{attrs:{"item-action":_vm.fieldDateInit,"on-success":_vm.actionFieldData}})],1),_c(VCol,{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('DatePicker',{attrs:{"item-action":_vm.fieldDateFinal,"on-success":_vm.actionFieldData}})],1),_c(VCol,{attrs:{"cols":"12","sm":"4","md":"3"}},[_c(VBtn,{attrs:{"color":"success","dark":""},on:{"click":_vm.searchSales}},[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v("mdi-calendar-search")]),_vm._v(" Buscar ")],1)],1)],1)],1)],1),_c('DataTableMain',{attrs:{"datatable":_vm.table,"on-success":_vm.successtableAction,"items-action":_vm.successtableAction}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('ViewDetailsMain',{staticClass:"my-2",attrs:{"object":_vm.objectDetail,"type":"VentasDetails","items-action":_vm.successtableAction}})],1)],1),(_vm.get_dialogMain.dialog)?_c('MainFormDialog',{attrs:{"model":_vm.object,"params":_vm.paramsDialog,"on-success":_vm.ResponseForm}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }