import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{attrs:{"outlined":"","loading":_vm.CardLoading}},[_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VFileInput,{attrs:{"show-size":"","small-chips":"","label":"Cargar Arhivo","outlined":"","dense":""},on:{"change":_vm.setFile}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"Link","outlined":"","dense":""},model:{value:(_vm.sendImport.link),callback:function ($$v) {_vm.$set(_vm.sendImport, "link", $$v)},expression:"sendImport.link"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"Clave","outlined":"","dense":""},model:{value:(_vm.sendImport.key),callback:function ($$v) {_vm.$set(_vm.sendImport, "key", $$v)},expression:"sendImport.key"}})],1)],1)],1),_c(VCardActions,[_c(VLayout,{attrs:{"justify-end":""}},[_c(VBtn,{staticClass:"green darken-1 mr-3",attrs:{"dark":"","outlined":""},on:{"click":_vm.sendData}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-cloud-upload ")]),_vm._v("Guardar ")],1)],1)],1),_c('template',{slot:"progress"},[_c(VProgressLinear,{attrs:{"color":"light-green darken-3","height":"5","indeterminate":""}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }