import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{attrs:{"dense":""}},[_c(VCard,{attrs:{"width":"100%","tile":""}},[_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('DatePicker',{attrs:{"item-action":_vm.fieldDateInit,"model":"fruit","on-success":_vm.actionFieldData}})],1),_c(VCol,{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('DatePicker',{attrs:{"item-action":_vm.fieldDateFinal,"on-success":_vm.actionFieldData}})],1),_c(VCol,{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('SelectedDinamicField',{attrs:{"setter-model":_vm.params.sucursal_id,"item-action":_vm.SelectedSucursalField,"on-success":_vm.actionFieldData}})],1),_c(VCol,{attrs:{"cols":"12","align":"right"}},[(_vm.visor)?_c('PreviewPDF',{attrs:{"itemAction":this.itemAction,"setterModel":this.params}}):_vm._e()],1),_c(VCol,{attrs:{"cols":"12","align":"right"}},[_c(VBtn,{attrs:{"color":"success","dark":""},on:{"click":_vm.searchReportUtilidad}},[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v("mdi-file-excel")]),_vm._v("Descargar Excel ")],1)],1)],1)],1)],1),_c(VCol,{attrs:{"cols":"12"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }