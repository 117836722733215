import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":"40","nudge-top":"30","transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({staticClass:"font-weight-bold black--text",attrs:{"label":_vm.itemAction.name,"prepend-inner-icon":"mdi-calendar","clearable":"","readonly":"","dense":"","outlined":""},on:{"click:clear":function($event){return _vm.clearData()}},model:{value:(_vm.dateMain),callback:function ($$v) {_vm.dateMain=$$v},expression:"dateMain"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"show-adjacent-months":"","min":"1950-01-01","year-icon":"mdi-calendar-blank","prev-icon":"mdi-skip-previous","next-icon":"mdi-skip-next","locale":"es"},on:{"change":_vm.save},model:{value:(_vm.dateMain),callback:function ($$v) {_vm.dateMain=$$v},expression:"dateMain"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }