<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card tile>
          <v-card-text>
            <v-row dense>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <SelectedDinamicField
                  :setter-model="sucursal_id"
                  :item-action="SelectedSucursalField"
                  :on-success="actionFieldData"
                />
              </v-col>
              <v-col
                v-if="showAlmacen"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <SelectedDinamicField
                  :setter-model="almacen_id"
                  :item-action="SelectedAlmacenField"
                  :on-success="actionFieldData"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <DataTableMainServer
          v-if="showTable"
          :datatable="table"
          :on-success="successAction"
          :items-action="tableAction"
          :updatepagination="params"
          :on-pagination="pagePagination"
          :on-search="getSearchPagePagination"
        />
      </v-col>
      <v-col cols="12">
        <ViewDetailsMain
          class="my-2"
          type="ArticulosDetails"
          :object="objectDetail"
          :items-action="tableAction"
        />
      </v-col>
    </v-row>
    <MainFormDialog
      v-if="get_dialogMain.dialog"
      :model="object"
      :params="paramsDialog"
      :on-success="ResponseForm"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DataTableMainServer from '@/components/tables/DataTableMainServer'
import MainFormDialog from '@/components/dialogs/MainFormDialog'
import SelectedDinamicField from '@/components/fields/SelectedDinamicField'
import ViewDetailsMain from '@/components/details/ViewDetailsMain.view'
import { UserSession } from '@/store/modules/config'
import { Permission } from '@/mixins/permissionMain'
export default {
  components: {
    DataTableMainServer,
    MainFormDialog,
    SelectedDinamicField,
    ViewDetailsMain
  },
  mixins: [Permission],
  data: () => ({
    table: {
      modelMain: [],
      showSelect: true,
      singleSelect: true,
      header: {
        options: [
          { title: 'Refrescar', icon: 'mdi-cached', color: 'primary', action: 'refreshData', code: 'libre', hidden: '' },
          { title: 'Lista de Precios', icon: 'mdi-clipboard-list', color: 'green darken-2', action: 'dowloadPreciosData', code: 'inventario.reporte.general-precios-pdf' }
        ],
        titles: [
          { text: 'Nombre Producto', value: 'caracteristicas.descripcion', class: 'black--text' },
          // { text: 'Marca', value: 'caracteristicas.marca.nombre', class: 'black--text' },
          { text: 'Familia', value: 'caracteristicas.familiabread', class: 'black--text' },
          { text: 'Empresa', value: 'empresa.nombre', class: 'black--text' },
          { text: 'Activo', value: 'Activo', class: 'black--text' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: []
      },
      footer: {
        paging: true,
        page_init: 1
      }
    },
    paramsDialog: {},
    object: {},
    params: {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 10,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: [],
      sortDesc: []
    },
    default: { groupBy: [], groupDesc: [], itemsPerPage: 10, multiSort: false, mustSort: false, page: 1, sortBy: [], sortDesc: [] },
    sucursal_id: 0,
    almacen_id: 0,
    SelectedSucursalField: { field: 'selectDataServer', name: 'Sucursales', nameid: 'sucursal_id', url: '/empresa/sucursales', parmas: '', cols: 3 },
    SelectedAlmacenField: { field: 'selectDataServer', name: 'Almacenes', nameid: 'almacen_id', url: '/inventario/almacenes/list-sucursal', parmas: '', cols: 3 },
    showAlmacen: false,
    showSucursal: false,
    showTable: false,
    titleDialog: ''
  }),
  computed: {
    ...mapGetters(['get_urls', 'get_dialogMain', 'get_objects',
      'get_PrecioArticuloForm', 'get_ArticuloFom', 'get_ShowPdfticketDialog', 'get_ShowPreciosArticulos']),
    objectDetail () {
      if (this.table.modelMain.length > 0) {
        return this.table.modelMain[0]
      }
      return {}
    }
  },
  watch: {
    get_urls (val) {
      // this.ExecuteUrl('POST/catalogo/productos/params')
      this.ExecuteUrl('POST/catalogo/productos/pagination/show')
    },
    get_objects (rows) {
      if (rows) {
        this.table.body.data = []
        this.table.modelMain = []
        this.table.body.data = rows.data
        this.table.body.totalData = rows.total
        if (rows.data.length > 0) this.table.modelMain.push(rows.data[0])
        this.table.header.loading = false
      }
    }
  },
  mounted () {
    this.sucursal_id = UserSession().sucursal_id
    this.almacen_id = UserSession().almacen_id
    // if (this.showMainPermiss('')) { this.showSucursal = true }
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    ...mapActions(['GETUrlServices', 'POSTListObjectsService', 'GETObjectService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'CLEAR_LIST_DATA', 'LOADING_MAIN_DIALOG']),
    // *==========*==========*==========* Methods Table Pagination *==========*==========*==========* \\
    pagePagination (val) {
      this.table.header.loading = true
      this.params = (val) || this.default
      this.params.sucursal_id = this.sucursal_id
      this.params.almacen_id = this.almacen_id
      this.ExecuteUrl('POST/catalogo/productos/pagination/show')
    },
    getSearchPagePagination (val) {
      this.table.header.loading = true
      this.params = val
      this.params.sucursal_id = this.sucursal_id
      this.params.almacen_id = this.almacen_id
      this.ExecuteUrl('POST/catalogo/productos/pagination/show')
    },
    actionFieldData (obj) {
      this[obj.id] = (obj.data) ? obj.data : null
      if (obj.id === 'sucursal_id') {
        this.params.almacen_id = null
        if (obj.data) {
          this.showAlmacen = false
          this.SelectedAlmacenField.params = `/${obj.data}`
          setTimeout(() => { this.showAlmacen = true }, 1)
        } else {
          this[obj.id] = 0
          // this.sucursal_id = 0
          this.SelectedAlmacenField.params = ''
          this.showAlmacen = false
        }
      }
      if (!this.showTable) this.showTable = true
      else this.refreshData(this.params)
    },
    showMainPermiss (permiso) {
      return this.ReadOnlyPermiso(JSON.parse(localStorage.getItem('permisos')), permiso)
    },
    /* *==========*==========*==========*==========*==========* */
    // *==========*==========*==========*==========*==========* \\
    successAction (item) {
      this[item.action](item)
    },
    refreshData (item) {
      this.table.header.loading = true
      this.params.sucursal_id = this.sucursal_id
      this.params.almacen_id = this.almacen_id
      this.ExecuteUrl('POST/catalogo/productos/pagination/show')
    },
    dowloadPreciosData (item) {
      if (!this.sucursal_id) {
        this.$swal({
          type: 'warning',
          icon: 'warning',
          title: 'Advertencia',
          text: 'Debe seleccionar una sucursal'
        })
        return
      }
      if (!this.almacen_id) {
        this.$swal({
          type: 'warning',
          icon: 'warning',
          title: 'Advertencia',
          text: 'Debe seleccionar un almacen'
        })
        return
      }
      this.object = { sucursal_id: this.sucursal_id, almacen_id: this.almacen_id }
      this.paramsDialog = {
        form: this.get_ShowPreciosArticulos,
        url: this.get_urls['POST/inventario/execute'],
        message: 'Registro Agregado Correctamente',
        setmodel: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'precios', with: '85%', title: 'Lista de Precios', body: { isbutton: true } })
    },

    newDataArticulo ({ item }) {
      this.object = { producto_id: item.id, solicitud_id: 0, facturado: false, cantidad: 0, isservicio: false }
      this.paramsDialog = {
        urlprocess: this.get_urls['GET/inventario/options[/{status_id}]'],
        url: this.get_urls['POST/inventario/execute'],
        message: 'Registro Agregado Correctamente',
        setmodel: true,
        returnObject: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'createArtUdm', form: 'process', with: 850, title: 'Nueva Unidad de Venta' })
    },
    updateDataArticulo ({ item }) {
      this.object = item.caracteristicas
      this.object.equivalente = item.inventarios.equivalente
      this.object.udm_equivalente_id = item.inventarios.udm_equivalente_id
      this.object.id = item.id
      this.object.folio = item.folio
      this.object.cantidad_minima = item.cantidad_minima
      this.object.almacen_id = item.almacen_id
      this.object.udm_id = item.udm_id
      this.object.tipo_impuesto_id = item.tipo_impuesto_id

      this.paramsDialog = {
        form: this.get_ArticuloFom,
        url: this.get_urls['POST/inventario/articulos'],
        message: 'Registro Actualizado Correctamente',
        setmodel: true,
        returnObject: true
      }
      // form: 'process',
      this.SHOW_DIALOG_MAIN({ type: 'createArtUdm', with: 850, title: 'Editar Unidad' })
    },
    newDataPrecioArticulo ({ item }) {
      this.object = { articulo_id: item.id }
      this.paramsDialog = {
        form: this.get_PrecioArticuloForm,
        url: this.get_urls['POST/inventario/precios'],
        message: 'Registro Agregado Correctamente',
        setmodel: true,
        returnObject: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'createPreceArt', form: 'process', with: 850, title: 'Nuevo Unidad' })
    },
    tableAction (item) {
      this[item.action](item)
    },
    toReview (item) {
      this.object = item
      this.paramsDialog = {
        url: this.get_urls['POST/compra/execute'],
        message: 'Registro Agregado Correctamente'
      }
      this.SHOW_DIALOG_MAIN({
        type: 'revision',
        form: 'process',
        with: 650,
        title: 'FINALIZAR LA REVISION'
      })
    },
    toApprove (item) {
      this.object = item
      this.paramsDialog = {
        url: this.get_urls['POST/compra/execute'],
        message: 'Registro Agregado Correctamente'
      }
      this.SHOW_DIALOG_MAIN({
        type: 'create',
        form: 'process',
        with: 650,
        title: 'APROVAR LA ORDEN DE COMPRA'
      })
    },
    toPurchase (item) {
      this.object = item
      this.paramsDialog = {
        url: this.get_urls['POST/compra/execute'],
        message: 'Registro Agregado Correctamente'
      }
      this.SHOW_DIALOG_MAIN({
        type: 'comprar',
        form: 'process',
        with: 650,
        title: 'REALIZAR COMPRA'
      })
    },
    toReceive (item) {
      this.object = item
      this.paramsDialog = {
        url: this.get_urls['POST/compra/execute'],
        message: 'Registro Agregado Correctamente',
        setmodel: true,
        data: { productos: item.item.detalles }
      }
      this.SHOW_DIALOG_MAIN({
        type: 'comprar',
        form: 'process',
        with: 650,
        title: 'RECEPCIONAR COMPRA'
      })
    },
    toCheckIn (item) {
      // console.log('item', item)
      this.object = item
      this.paramsDialog = {
        url: this.get_urls['POST/compra/execute'],
        message: 'Registro Agregado Correctamente',
        setmodel: true,
        data: { confirmar: true }
      }
      this.SHOW_DIALOG_MAIN({
        type: 'cerrar',
        form: 'process',
        with: 650,
        title: 'CERRAR COMPRA'
      })
    },
    toBill (item) {},
    toCancel (item) {},
    // *==========*==========*==========*==========* \\
    ResponseForm ({ name, model }) {
      if (name === 'createArtUdm') {
        const producto = this.table.body.data.find(producto => producto.id === model.producto_id)
        let finded = -1
        producto.articulos.map((articulo, index) => {
          if (articulo.id === model.id) finded = index
        })
        if (finded !== -1) Object.assign(producto.articulos[finded], model)
        else producto.articulos.push(model)
      } else if (name === 'createPreceArt') {
        let finded = -1
        let disable = -1

        const producto = this.table.body.data.find(producto => producto.id === model.articulo.producto_id)
        const articulo = producto.articulos.find(articulo => articulo.id === model.articulo.id)

        articulo.precios.map((precio, index) => {
          if (precio.id === model.id) finded = index
          if (precio.tipo_precio_id === model.tipo_precio_id && precio.activo === 'si') disable = index
        })

        if (finded !== -1) Object.assign(articulo.precios[finded], model)
        else articulo.precios.push(model)

        if (disable !== -1) articulo.precios[disable].activo = 'no'
      }
    },
    ExecuteUrl (url) {
      if (this.get_urls != null) {
        const router = this.get_urls[url]
        this.POSTListObjectsService({ url: router, data: this.params })
      } else {
        this.GETUrlServices()
      }
    }
  }
}
</script>

<style>
</style>
