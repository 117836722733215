<template>
  <v-card width="auto" tile elevation="0">
    <v-card-text>
      <v-form ref="form">
        <v-row dense>
          <v-col v-for="(field, i) in form" :key="i" :cols="field.cols" :offset="field.offset" :hidden="field.hidden">
            <p v-if="field.field == 'viewText' && !field.hidden" :class="field.classMain">
              <label class="font-weight-bold black--text">{{ field.name }}: </label> {{ model[field.nameid] }}
            </p>
            <p v-if="field.field == 'viewTextList' && !field.hidden">
              <label class="font-weight-bold">{{ field.name }} </label>
              <v-chip v-for="(item, index) in model[field.nameid]" :key="index"
                :color="field.color" class="ma-1" small label outlined>
                <span class="font-weight-bold text-uppercase">{{ item[field.itemnameid] }}</span>
              </v-chip>
            </p>
            <p v-if="field.field == 'messageViewText' && !field.hidden" :class="field.classMain">
              <label class="font-weight-bold font-weight-black">{{ field.name }} </label>
            </p>
            

            <v-text-field
              v-if="field.field == 'text' && !field.hidden"
              v-model="model[field.nameid]"
              :label="field.name"
              :type="field.type"
              :rules="field.rules ? fieldRequired : []"
              :readonly="field.readonly"
              outlined
              dense
            />
            <v-text-field
              v-if="field.field == 'textEmail' && !field.hidden"
              v-model="model[field.nameid]"
              :label="field.name"
              :type="field.type"
              :rules="field.rules ? emailRules : []"
              :readonly="field.readonly"
              outlined
              dense
            />

            <v-text-field
              v-if="field.field == 'number' && !field.hidden"
              v-model.number="model[field.nameid]"
              :label="field.name"
              :type="field.type"
              min="0"
              :rules="field.rules ? fieldRequired : []"
              :readonly="field.readonly"
              outlined
              dense
            />

            <v-textarea
              v-else-if="field.field == 'textArea' && !field.hidden"
              v-model="model[field.nameid]"
              :label="field.name"
              :name="field.dimens"
              :rules="field.rules ? fieldRequired : []"
              outlined
              dense
            />

            <v-select
              v-else-if="field.field == 'select' && !field.hidden"
              v-model="model[field.nameid]"
              :label="field.name"
              :items="field.items"
              :rules="field.rules ? fieldRequired : []"
              outlined
              dense
            />

            <div v-else-if="field.field == 'radioButton' && !field.hidden">
              <label class="black--text font-weight-bold font-weight-black subtitle-2">{{ field.name }}</label>
              <v-radio-group
                v-model="model[field.nameid]"
                :row="field.row"
                :rules="(field.rules)? fieldRequired : []"
                class="pa-1 ma-1"
                @change="RadioButtonEvent(field)"
              >
                <v-radio
                  v-for="(value,index) in field.items"
                  :key="index"
                  :label="(value.name)? value.name : value"
                  :value="(value.name)? value.value : value"
                />
              </v-radio-group>
            </div>

            <div v-else-if="field.field == 'checkbox'">
              <p class="ma-0 pa-0 text--black font-weight-bold font-weight-black subtitle-2">
                {{ field.name }}
              </p>
              <v-checkbox
                v-for="value in field.items"
                :key="value"
                v-model="model[field.nameid]"
                :class="field.row ? 'mr-3 pa-0 checkbook-horizontal' : 'ma-0 pa-0'"
                :label="value"
                :value="value"
                :rules="field.rules ? fieldRequired : []"
              />
            </div>

            <SelectedDinamicField
              v-else-if="field.field == 'selectDataServer' && !field.hidden"
              :setter-model="model[field.nameid]"
              :item-action="field"
              :on-success="actionFieldData"
            />

            <SearchDinamicField
              v-else-if="field.field == 'searchDataServer' && !field.hidden"
              :setter-model="model"
              :item-action="field"
              :on-success="actionFieldData"
            />

            <SearchField v-else-if="field.field == 'tableDataServer'&& !field.hidden" :item-action="field" :on-success="actionFieldData"/>

            <DatePicker
              v-else-if="field.field == 'textDatePicker' && !field.hidden"
              :itemAction="field"
              :on-success="actionFieldData"
            />

            <SelectedGroupDinamicField
              v-else-if="field.field == 'selectGroupDataServer' && !field.hidden"
              :setterModel="model[field.nameid]"
              :itemAction="field"
              :on-success="actionFieldData"
            />
            <PricesArticleField
              v-else-if="field.field == 'showtableDataServer' && !field.hidden"
              :setterModel="model[field.nameid]"
              :itemAction="field"
              :on-success="actionFieldData"
            />
            <ViewerPdfField
              v-else-if="field.field == 'showPdfDataServer' && !field.hidden"
              :setterModel="model[field.nameid]"
              :itemAction="field"
              :on-success="actionFieldData"
            />

            <PreviewPDF v-else-if="field.field == 'previewpdf' && !field.hidden"
            :setterModel="model[field.nameid]"
            :itemAction="field" />

            <ShowDetailCaja v-else-if="field.field == 'ShowDetailCaja' && !field.hidden"
              :setterModel="model"
              :itemAction="field" />
          </v-col>
        </v-row>
      </v-form>
      <v-snackbar v-model="snackbar_precio" :timeout="timeout" color="warning" top>
        <span class="font-weight-bold title"> No cuenta con precio de compra</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar_precio = false" dark> Cerrar </v-btn>
      </template>
    </v-snackbar>
    </v-card-text>

    <v-card-actions>
      <v-layout justify-end>
        <v-btn
          class="mr-3"
          color="red darken-1"
          depressed
          outlined
          @click="onCancel"
        >
          Cerrar
        </v-btn>
        <v-btn v-if="vissible_button" class="mr-3" color="success darken-1" @click="sendForm" depressed outlined>
          {{title_button}}
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Validate } from '@/mixins/validateFormMixin';
import SelectedDinamicField from '@/components/fields/SelectedDinamicField';
import SearchDinamicField from '@/components/fields/SearchDinamicField';
import SearchField from '@/components/fields/genericField/SearchField.vue';
import DatePicker from '@/components/fields/DatePicker'
import SelectedGroupDinamicField from '@/components/fields/SelectedGroupDinamicField'
import PricesArticleField from '@/components/fields/genericField/PricesArticleField.vue'
import ViewerPdfField from '@/components/fields/ViewerPdfField.vue'
import PreviewPDF from '@/components/fields/specific/PreviewPDF'
import ShowDetailCaja from '@/components/fields/specific/ShowDetailCajaField'

export default {
  name: 'MainProcessForm',
  components: {
    SelectedDinamicField,
    SearchDinamicField,
    DatePicker,
    SelectedGroupDinamicField,
    PricesArticleField,
    SearchField,
    ViewerPdfField,
    PreviewPDF,
    ShowDetailCaja
},
  mixins: [Validate],
  props: ['form', 'model', 'params', 'onSuccess', 'onCancel'],
  data: () => ({
    selected: [],
    indice: '',
    title_button: 'Guardar',
    snackbar_precio: false,
    timeout: 2000,
    vissible_button: true
  }),
  mounted() {
  },
  created() {
    if (this.params) {
      if (this.params.rename) this.title_button = this.params.name_button
      this.vissible_button = !this.params.isbutton
    }
  },
  methods: {
    ...mapActions([]),
    RadioButtonEvent (item) {
      if (item.action) {
        this[item.action](item)
      }
    },
    actionDinamicData(obj) {
      if (!obj) return
      if (obj.fieldMain.action) {
        this[obj.fieldMain.action](obj)
      }
    },
    actionFieldData (obj) {
      this.model[obj.id] = (obj.data) ? obj.data : null
      if (obj.nameObject) this.model[obj.nameObject] = (obj.objectMain) ? obj.objectMain : null

      if (!obj.fieldMain) return

      if (obj.fieldMain.action) {
        this[obj.fieldMain.action](obj);
      }
      // this.actionDinamicData(obj);
    },
    ShowFieldDinamic ({ id, data, objectMain, fieldMain }) {
      // console.log('ShowFieldDinamic')
      if (fieldMain.isHidden) {
        fieldMain.isHidden.map((item, i) => {
          // console.log(data)
          this.form.map((form) => {
            if (item === form.nameid) {
              if (item === 'articulo_id' && data) {
                form.params[fieldMain.nameid] = data
                return
              } else if (item === 'producto_id' && data) {
                form.params[fieldMain.nameid] = data
                form.hidden = false
                this.model[form.nameid] = ''
                return
              } else if (item === 'default' && data) {
                form.hidden = true
                form.url = `${form.url_default}/${data}`
                this.model[form.nameid] = null
                setTimeout(() => { form.hidden = false }, 500);
                return
              } else if (item === 'almacenes' && data) {
                form.hidden = true
                form.url = `${form.url_default}/${data}`
                this.model[form.nameid] = []
                setTimeout(() => { form.hidden = false }, 500);
                return
              } else if (item === 'ruta' && (fieldMain.nameid === 'fecha_final' || fieldMain.nameid === 'fecha_inicio')) {
                if (this.model.tipo_reporte === 'excel') return
                form.hidden = true
                form.params = {
                  fecha_inicial: this.model.fecha_inicio,
                  fecha_final: this.model.fecha_final,
                  sucursal_id: this.model.sucursal_id,
                  facturado: this.model.facturado,
                  bultos: this.model.bultos
                }
                this.model[form.nameid] = form.params
                setTimeout(() => {
                  // console.log('ruta', form)
                  form.hidden = false 
                }, 500);
                // this.model[form.nameid] = ''
                return
              }else if (data) {
                form.hidden = false
                this.model[form.nameid] = ''
                return
              }
              form.hidden = true
              this.model[form.nameid] = null
            }
          })
        })
      }
    },
    ShowFieldDinamicSimple(fieldMain) {
      const data = this.model[fieldMain.nameid]
      if (fieldMain.isHidden) {
        fieldMain.isHidden.map((item, i) => {
          this.form.map((form) => {
            if (item === form.nameid) {
              if (item === 'ruta' && data === 'pdf') {
                this.model[form.nameid] = {
                  fecha_inicial: this.model.fecha_inicio,
                  fecha_final: this.model.fecha_final,
                  sucursal_id: this.model.sucursal_id,
                  facturado: this.model.facturado,
                  bultos: this.model.bultos
                }
                form.hidden = false
                this.vissible_button = false
                // this.model[form.nameid] = ''
                return
              } else if (data) {
                form.hidden = true
                this.vissible_button = true
                this.model[form.nameid] = ''
                return
              }
              form.hidden = true
              this.model[form.nameid] = null
            }
          })
        })
      }
    },
    ShowHiddenFieldDataDinamic ({ id, data, objectMain, fieldMain }) {
      const objet = objectMain
      // console.log('objectMain', objectMain)
      if (fieldMain.isHidden) {
        fieldMain.isHidden.map((item) => {
          this.form.map((fld) => {
            if (item === fld.nameid) {
              if (fld.nameid === 'articulo_id' && data) {
                this.model[item] = null
                this.model[fld.objectname] = null
                fld.hidden = true
                fld.items = objet.articulos
                fieldMain.applicate.map(applica => {
                  this.model[applica] = 0
                })
                setTimeout(() => { fld.hidden = false }, 500)
                return
              } else if (fld.nameid === 'prices' && data) {
                if (objectMain.precios) {
                  let precioArt = 0
                  const precio = objectMain.precios.find(p => p.tipo_precio.tipo === 'in' && p.activo === 'si')
                  this.model.precio_compra = (precio) ? precio : null
                  precioArt = (precio) ? precio.precio : 0
                  fieldMain.applicate.map(applica => { this.model[applica] = precioArt })
                  if (precioArt <= 0) this.snackbar_precio = true

                  fld.hidden = true
                  this.model[fld.nameid] = (objectMain.tipospreciosarticulos)? objectMain.tipospreciosarticulos : []
                  setTimeout(() => { fld.hidden = false }, 500);
                }
                return
              } else if (fld.nameid === 'udm_id' && data) {
                this.model[fld.nameid] = objectMain[fld.nameid]
                this.model[fld.objectname] = objectMain[fld.objectname]
                return
              } else if (fld.nameid === 'tipo_impuesto_id' && data) {
                this.model[fld.nameid] = null
                this.model[fld.objectname] = null
                if (objectMain) {
                  this.model[fld.nameid] = objectMain[fld.nameid]
                  this.model[fld.objectname] = objectMain[fld.objectname]
                }
                fld.hidden = false
                return
              } else if (fld.nameid === 'pdf' && data) {
                fld.hidden = true
                fld.params = '?'
                // console.log('fieldMain', fieldMain)
                if (fieldMain.addicional) {
                  fieldMain.addicional.map(add => {
                    fld.params += (fld.params === '?') ? `${add}=${this.model[add]}` : `&${add}=${this.model[add]}`
                  })
                  fld.params += (fld.params === '?') ? `${id}=${data}` : `&${id}=${data}`
                }
                setTimeout(() => { fld.hidden = false }, 500)
                return
              }
              fld.hidden = true
              if(fld.items) fld.items = []
              if(this.model[fld.nameid]) this.model[fld.nameid] = null
              if(this.model[fld.objectname]) this.model[fld.objectname] = null
            }
          })
        })
      }
    },
    findFieldForm (field) {
      this.form.map((fld) => {
        if (field === fld.nameid) {
          fld.hidden = false
          this.model[fld.nameid] = ''
          return
        }
      })
    },

    assignArticulosUnidad (field) {
      const response = this.model[field.nameid]
      const articulos = this.model[field.objectname].articulos
        if(field.isHidden) {
          field.isHidden.map((item) => {
            this.form.map((fld) => {
              if (item === fld.nameid) {
                if (response) {
                  fld.items = []
                  fld.items.push(...articulos)
                  fld.hidden = false
                  return
                }
                fld.hidden = true
                this.model[fld.nameid] = null
              }
            })
          })
        }
    },
    sendForm() {
      if (this.$refs.form.validate()) {
        const item = this.validateComponents()
        if (item !== null) {
          this.$swal({
            type: 'error',
            icon: 'error',
            title: 'Advertencia!',
            text: ` Campo ${item.name} no debe ser vacio!!`
          })
          return
        }
        this.onSuccess(this.model)
      }
    },
    validateComponents() {
      let itemSelected = null;
      this.form.map((item) => {
        if (item.rules) {
          if (!item.hidden) {
            if (!this.model[item.nameid]) {
              itemSelected = item;
            } else if (this.model[item.nameid] === null) {
              itemSelected = item;
            }
          }
        }
      });
      return itemSelected;
    },
  },
  computed: {
    ...mapGetters([]),
  },
  watch: {
  },
};
</script>

<style>
.box-color-red {
  border: 1px red solid;
}
div .checkbook-horizontal {
  display: inline-block;
}
</style>
