export const Permission = {
    data: () => ({}),
    methods: {
      ShowPermisos ({ permisos, tableMain }) {
        if (permisos.descripcion.special === 'all-access') {
          tableMain.header.options.map(action => {
            action.hidden = ''
            if (action.options) {
              action.options.map(option => {
                option.hidden = ''
              })
            }
          })
          tableMain.body.actions.map(action => {
            action.hidden = ''
          })
          return
        }
        tableMain.header.options.map(action => {
          permisos.permisos.map(item => {
            if (action.code !== 'libre') {
              if (action.code === item.slug) {
                action.hidden = ''

                if (action.options) {
                  action.options.map(sub_action => {
                    permisos.permisos.map(permiss => {
                      if (sub_action.code !== 'libre') {
                        if (sub_action.code === permiss.slug) sub_action.hidden = ''
                      }
                    })
                  })
                }

              }
            }
          })
        })
        tableMain.body.actions.map(action => {
          permisos.permisos.map(item => {
            if (action.code !== 'libre') {
              if (action.code === item.slug) {
                action.hidden = ''
              }
            }
          })
        })
      },
  
      PermisosMenu (permisos, menuGral) {
        if (permisos && permisos.descripcion.special === 'all-access') {
          menuGral.map(action => {
            if (action.group) {
              action.hidden = ''
              action.items.map(subItems => {
                if (subItems.group) {
                  subItems.hidden = ''
                  subItems.items.map(subsubItems => {
                    subsubItems.hidden = ''
                  })
                } else {
                  subItems.hidden = ''
                }
              })
            } else {
              action.hidden = ''
            }
          })
          return
        }
        menuGral.map(action => {
          permisos.permisos.map(item => {
            if (action.group) { // console.log('primero', `${item.slug} -- ${action.id} `)
              if (item.slug === action.id) {
                action.hidden = ''
                action.items.map(subItems => {
                  permisos.permisos.map(per => {
                    if (subItems.group) {
                      if (per.slug === subItems.id) {
                        subItems.hidden = ''
                        subItems.items.map(subsubItems => {
                          permisos.permisos.map(persub => {
                            if (persub.slug === subsubItems.id) {
                              subsubItems.hidden = ''
                            }
                          })
                        })
                      }
                    } else {
                      if (per.slug === subItems.id) {
                        subItems.hidden = ''
                      }
                    }
                  })
                })
              }
            } else {
              if (item.slug === action.id) action.hidden = ''
            }
          })
        })
      },

      PermisosMenuTwo (permisos, menuGral) {
        if (permisos && permisos.descripcion.special === 'all-access') {
          menuGral.map(action => {
            if (action.group) {
              action.hidden = ''
              action.items.map(subItems => {
                if (subItems.group) {
                  subItems.hidden = ''
                  subItems.items.map(subsubItems => {
                    subsubItems.hidden = ''
                  })
                } else {
                  subItems.hidden = ''
                }
              })
            } else {
              action.hidden = ''
            }
          })
          return
        }
        menuGral.map(action => {
          permisos.permisos.map(item => {
            if (action.group) {
              if (item.slug === action.id) {
                action.hidden = ''
                this.PermisosSubMenu(permisos.permisos,action.items)
              }
            } else {
              action.hidden = (item.slug === action.id) ? '' : action.hidden
            }
          })
        })
      },

      PermisosSubMenu(permisos,menu) {
        menu.map(action => {
          permisos.map(permiso => {
            if (action.group) {
              if (permiso.slug === action.id) {
                action.hidden = ''
                this.PermisosSubMenu(permisos,action.items)
              }
            } else {
              action.hidden = (permiso.slug === action.id) ? '' : action.hidden
            }
          })
        })
      },

      ReadOnlyPermiso (permisos, permiso) {
        if (permisos.descripcion.special === 'all-access') {
          return true
        }
        const acceso = permisos.permisos.find(item => item.slug === permiso)
        return (acceso) ? true : false
      }
    }
  }
  