<template>
  <v-card v-if="model.id" class="elevation-2" tile>
    <v-card-text>
      <v-row dense>
        <v-col cols="9">
          <p class="text-md-h4 text-sm-h5 text-center">Permisos del Rol</p>
        </v-col>
        <v-col cols="3">
          <v-layout justify-end>
            <v-btn v-if="showMainPermiss('accesos.roles.set-permiso')" color="green darken-2" @click="sendPermission" dark>
            <v-icon left>mdi-check-outline</v-icon> Guardar Permisos
            </v-btn>
          </v-layout>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <v-card outlined>
            <v-card-text>
              <v-row dense v-if="permisosMain.length > 0">
                <v-col cols="12">
                  <v-treeview v-model="selection"
                    selection-type="independent"
                    :items="permisosMain"
                    return-object
                    :selectable="isPermiss">
                  </v-treeview>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="8" md="8">
          <v-card outlined>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" v-if="selection.length > 0">
                  <DataTableMain :datatable="table" :on-success="successAction" :items-action="tableAction" />
                </v-col>
                <!-- <v-col cols="12" v-for="(item, i) in selection" :key="i">
                  {{ item.nombre }} - ( {{ item.descripcion }} )
                </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'
import DataTableMain from '@/components/tables/DataTableMain'
import { Permission } from '@/mixins/permissionMain'
export default {
  mixins: [Permission],
  props: ['model', 'onSuccess'],
  name: 'UsuarioDetails',
  data: () => ({
    table: {
      header: {
        options: [],
        titles: [
          { text: 'Clave', value: 'slug', class: 'black--text' },
          { text: 'Nombre', value: 'nombre', class: 'black--text' },
          { text: 'Acceso', value: 'descripcion', class: 'black--text' }
        ],
        loading: false,
        showSearch: true
      },
      body: {
        data: [],
        actions: [
          { title: "Eliminar", icon: "mdi-delete", color: "error", action: "daleteDataRol", code: "libre", hidden: "" }
        ]
      },
      footer: {
        paging: true
      }
    },
    permisosMain: [],
    params: { name: 'ARBOL' },
    selection: [],
    isPermiss: false
  }),
  components: {
    DataTableMain
  },
  mounted () {
    // this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
    this.isPermiss = this.showMainPermiss('accesos.roles.set-permiso')
  },
  created() {},
  methods: {
    ...mapActions(['POSTListObjectstwoService']),
    ExecuteUrlTwo (objet) {
      const router = this.get_urls[objet]
      this.POSTListObjectstwoService({ url: router, data: this.params })
    },
    successAction (item) {
      item.item = this.model
      this.onSuccess(item)
    },
    tableAction (item) {
      item.model = this.model
      this.onSuccess(item)
    },
    // ==========*==========*==========*==========*========== \\
    // ==========*==========*==========*==========*========== \\
    showMainPermiss (permiso) {
      return this.ReadOnlyPermiso(JSON.parse(localStorage.getItem('permisos')), permiso)
    },
    sendPermission() {
      const item ={ action: 'permissionsAssign', item: { rol_id: this.model.id, permisos: this.selection } }
      this.onSuccess(item)
    }
  },
  computed: {
    ...mapGetters(['get_urls', 'get_object', 'get_objectsTwo'])
  },
  watch: {
    model (row) {
      this.selection = []
      if (row.permisos) {
        this.selection.push(...row.permisos)
        this.ExecuteUrlTwo('POST/empresa/accesos/permisos/show')
        // this.table.body.data = []
        // this.table.body.data.push(...this.selection)
        // console.log(this.selection)
      }
    },
    get_objectsTwo (row) {
      this.permisosMain = []
      this.permisosMain.push(...row)
    },
    selection (data) {
      this.table.body.data = []
      this.table.body.data.push(...data)
    }
  }
}
</script>
<style>
</style>
